/********************************************************
 * File: TrackerMetricsPage.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-01-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useEffect, useState, useRef, ReactNode, MutableRefObject } from 'react';
import { AppTheme } from '../../Theme';
import { makeStyles } from '@material-ui/styles';
import { DataGrid, GridColDef, GridSortModel } from '@material-ui/data-grid';
import { Box, Button, FormControl, MenuItem, Paper, Select, Tooltip, Typography } from '@material-ui/core';
import {
  Unsubscribe, getFirestore, doc, getDoc, DocumentSnapshot,
  DocumentData
} from '@firebase/firestore';
import { fetchTrackerTemplate, fetchData, paginatedFetchData } from '../../api/firestoreFetch';
import { ArrowBack } from '@material-ui/icons';
import SysInfo from '../core/SysInfo';
import { FilterSection, TypeFilterConfig } from '../../components/core/FilterSection';
import { TypeTracker } from '../../typings/tracker.types';

type TypeTemplateDataTableProps = {
  trackerId: string;
  template: any;
  domain: string;
  goalTypeAnnotations?: MutableRefObject<{[key: string]: string}>
}
const maxGridWidth = 993; // 95% of the page width
const statisticsTemplateId = 'statistics';

type TypePercentBarProps = { val: number, display: string, colWidth: number }

function PercentBar({ val, display, colWidth }: TypePercentBarProps) {
  const style = styles(AppTheme);
  let validWidth = val;
  if (val < 0) validWidth = 0;
  if (val > 100) validWidth = 100;
  
  let color = '#FFB74D'; // orange
  if (validWidth > 70) color = '#81C784'; // green
  else if (validWidth > 30) color = '#FFF176'; // yellow

  validWidth = colWidth * val / 100;

  return (
    <div className={style.percentBar} style={{ width: colWidth }}>
      <div
        className={style.percentBarComplete}
        style={{ width: validWidth, backgroundColor: color }}
      />
      <div
        className={style.percentBarText}
        style={{ width: colWidth*.88 }}
      >
        {display}
      </div>
    </div>
  );
}

// parses the columns of a datatable template, adding them to the `gridCols` array.
const parseDTCols = (cs: any) => {
  const gridCols: GridColWithSeq[] = [];

  // column widths
  let totalSpecifiedWidth = 0;
  let splitEvenlyCols = 0;
  cs.forEach((c: any) => {
    if (typeof c.width === 'number') totalSpecifiedWidth += c.width;
    else splitEvenlyCols += 1;
  });
  const remainingWidth = maxGridWidth - totalSpecifiedWidth;

  cs.forEach((c: any) => {
    if ((typeof c.key != 'string') || (typeof c.display != 'string')) return;

    const colWidth = c.width ?? (remainingWidth / splitEvenlyCols);

    const numFormatter = Intl.NumberFormat('en-US', {
      minimumFractionDigits: c.fractionDigits ?? 0,
      maximumFractionDigits: c.fractionDigits ?? 0
    });
    const parsed: GridColWithSeq = {
      field: c.key,
      headerName: c.display,
      width: colWidth,
      sortable: true,
      sequence: (typeof c.sequence === 'number') ? c.sequence : 10,
      align: c.rightAlign ? 'right' : 'left',
      headerAlign: c.rightAlign ? 'center' : 'left',
    };

    // percent bar
    if (typeof c.percentBar === 'boolean' && c.percentBar) {
      parsed.renderCell = (params) => {
        let value = params.value?.valueOf();        
        if (typeof value !== 'number') value = 0;

        let display = '';
        if (typeof value === 'number') {
          if (typeof c.suffix === 'string') {
            display = numFormatter.format(value) + c.suffix;
          } else {
            display = numFormatter.format(value);
          }
        } else if (typeof value === 'string') display = value;

        return <PercentBar val={value} display={display} colWidth={colWidth} />;
      }

    // tooltip and format value if no percent bar
    } else {
      parsed.renderCell = (params) => {
        let value = params.value?.valueOf();        
        if (typeof value !== 'string' && typeof value !== 'number') value = '';
        return (
          <Tooltip title={value}>
            <Typography variant='body2' style={{
              width: colWidth*.9,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
              {value}
            </Typography>
          </Tooltip>
        );
      }
      parsed.valueGetter = (params) => {
        const value = params.value;
        if (typeof value === 'number') {
          if (typeof c.suffix === 'string') {
            const formatted = numFormatter.format(value) + c.suffix;
            return formatted;
          } else {
            const formatted = numFormatter.format(value);
            return formatted;
          }
        } else return value;
      }
    }
    gridCols.push(parsed);
  });
  return gridCols;
}

function TemplateDataTable({
  trackerId,
  template,
  domain,
  goalTypeAnnotations
}: TypeTemplateDataTableProps) {
  const style = styles();
  const pageSize = 100;

  const [cols, setCols] = useState<GridColDef[]>([]);
  // get rows (wait until 'cols' data is set)
  const [rows, setRows] = useState<any[]>([]);

  // paginated fetch state
  // need both offsets to compare new one with old to figure out pagination
  // see fetchData()
  const [offset, setOffset] = useState(0); // offset updated by the pagination component
  // paginated list state
  const [totalRows, setTotalRows] = useState(-1);
  const currOffset = useRef(0); // offset currently shown in the list
  const firstDoc = useRef<TypeTracker>();
  const lastDoc = useRef<TypeTracker>();

  const unsubscribes = useRef<Unsubscribe[]>([]);
  const loading = useRef(false);
  const [orderByVal, setOrderByVal] = useState<string | undefined>();
  const [ascending, setAscending] = useState(false);

  // get columns for the statistics tracker template
  useEffect(() => {
    // clear previous data
    setRows([]);
    setCols([]);
    setTotalRows(0);
    setOffset(0);
    unsubscribes.current.forEach(u => u());

    const cs = template.columns;
    if (!Array.isArray(cs)) {
      console.error(`Error: Tracker Template with ID '${template.id}' has malformed 'columns' field`);
      return;
    }

    const gridCols = parseDTCols(cs);
    setCols(gridCols.sort((a, b) => a.sequence - b.sequence));
    if (typeof template.ascending === 'boolean') setAscending(template.ascending);
    if (typeof template.orderBy === 'string') setOrderByVal(template.orderBy);

    // aggregate columns
    const aggCols = template.footer;
    if (Array.isArray(aggCols)) {
      const colWidth = maxGridWidth / aggCols.length;
      const gridCols: GridColDef[] = [];
      aggCols.forEach(c => {
        if (!(typeof c.key === 'string' && typeof c.display === 'string')) return;
        gridCols.push({
          field: c.key,
          headerName: c.display,
          width: colWidth,
          sortable: false,
          align: c.rightAlign ? 'right' : 'left'
        });
      });
    }
  }, [template]);

  const parseItem = (d: DocumentSnapshot<DocumentData>) => {
    let temp: any = {
      id: JSON.stringify(d.data()),
      ...d.data(),
      user: d.get('user') ? d.get('user').replace(domain, '') : '',
    }
    const gtVal = d.get('goalType');
    if (goalTypeAnnotations && gtVal && goalTypeAnnotations.current.hasOwnProperty(gtVal)) {
      temp.goalType = temp.goalType + ' (' + goalTypeAnnotations.current[gtVal] + ')';
    }
    return temp;
  };
  
  useEffect(() => {
    if (typeof trackerId === 'string' && trackerId.length > 0) {
      // paginated fetch rows
      paginatedFetchData({
        collectionName: `Tracker/${trackerId}/DataTables/summary/${template.id}`,
        countDoc: `Tracker/${trackerId}/DataTables/summary`,
        parseCount: (d) => d.get(`counts.${template.id}`) ?? 0,
        setItems: setRows,
        loading: loading,
        unsubscribes: unsubscribes,
        totalItems: totalRows,
        setTotalItems: setTotalRows,
        currOffset: currOffset,
        offset: offset,
        pageSize: pageSize,
        firstDoc: firstDoc,
        lastDoc: lastDoc,
        parseItem: parseItem,
        orderByVal: orderByVal ?? template.orderBy ?? 'id',
        ascending: ascending,
      });
    }
    // unsubscribe listener on cleanup
    return function cleanUp() {
      unsubscribes.current.forEach(u => u());
    }
  }, [cols, offset, ascending]);

  let rowCount = 0;
  if (typeof totalRows === 'number' && totalRows > 0) rowCount = totalRows;
  return (
    <div className={style.datagridContainer}>
      <DataGrid
        classes={{ 
          root: style.datagridRoot,
          columnHeader: style.datagridHeader,
        }}
        rows={rows}
        rowCount={rowCount}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        columns={cols}
        disableSelectionOnClick
        loading={loading.current}
        disableColumnMenu
        headerHeight={40}
        showColumnRightBorder
        paginationMode='server'
        onPageChange={page => setOffset(page * pageSize)}

        // onCellClick={} // copy value to clipboard

        sortingOrder={['asc', 'desc']}
        // sorting
        sortingMode='server'
        //sortModel={sortModel}
        onSortModelChange={(model: GridSortModel) => {
          // datagrid returns empty array when header is clicked a third time which
          //   makes the grid go back to initial sort state. only update our
          //   orderBy and ascending for a valid sort.
          if (model.length > 0) {
            // check if this column is sorted by another field,
            //    eg % complete (string) is sorted by the ratio (numeric) value
            setOrderByVal(model[0].field);
            setAscending(model[0].sort === 'asc' ? true : false);
          }
        }}
      />
    </div>
  );
}

type TypeContentHeaderProps = {
  content: ReactNode;
  title?: string;
  customHeader?: ReactNode;
}

function ContentHeader({ title, content, customHeader }: TypeContentHeaderProps) {
  const style = styles(AppTheme);
  return (
    <div className={style.contentHeaderContainer}>
      <div className={style.contentHeaderTitle}>
        {customHeader ?
          customHeader
          : <Typography color='secondary' variant='h6'>{title ?? ''}</Typography>
        }
      </div>
      {content}
    </div>
  );
}

type GridColWithSeq = GridColDef & { sequence: number };

const parseFilter = (f: any, id=false) => ({
  scope: f.keyDisplay ?? '',
  label: id ? `${f.display}: ${f.value}` : f.display,
});

const loadFilterConfigs = (allFilters: any, setFilterConfigs: React.Dispatch<React.SetStateAction<TypeFilterConfig[]>>) => {
  setFilterConfigs([
    {
      name: 'Goal Attributes',
      selected: allFilters.goal ? 
        allFilters.goal.filter((f: any) => f.operator !== 'NOT').map((f: any) => parseFilter(f)) 
        : [],
      chipColorGroup: 'goal',
      readOnly: true
    },
    {
      name: 'Excluded Goal Attributes',
      selected: allFilters.goal ? 
        allFilters.goal.filter((f: any) => f.operator === 'NOT').map((f: any) => parseFilter(f))
        : [],
      chipColorGroup: 'goal',
      readOnly: true
    },
    {
      name: 'Goal Period',
      selected: [{
        startDate: allFilters.goalStartDate ? allFilters.goalStartDate.toDate() : null,
        endDate: allFilters.goalEndDate ? allFilters.goalEndDate.toDate() : null
      }],
      dateSelector: true,
      readOnly: true
    },
    {
      name: 'Orgs',
      selected: allFilters.org ? 
        allFilters.org.filter((f: any) => f.operator !== 'NOT').map((f: any) => parseFilter(f, true))
        : [],
      chipColorGroup: 'account',
      readOnly: true
    },
    {
      name: 'Excluded Orgs',
      selected: allFilters.org ? 
        allFilters.org.filter((f: any) => f.operator === 'NOT').map((f: any) => parseFilter(f, true))
        : [],
      chipColorGroup: 'account',
      readOnly: true
    },
    {
      name: 'Product Hierarchies',
      selected: allFilters.ph ? 
        allFilters.ph.filter((f: any) => f.operator !== 'NOT').map((f: any) => parseFilter(f, true))
        : [],
      chipColorGroup: 'product',
      readOnly: true
    },
    {
      name: 'Excluded Product Hierarchies',
      selected: allFilters.ph ? 
        allFilters.ph.filter((f: any) => f.operator === 'NOT').map((f: any) => parseFilter(f, true))
        : [],
      chipColorGroup: 'product',
      readOnly: true
    },
  ]);
}

function TrackerMetricsPage(props: any) {
  const style = styles(AppTheme);

  const db = getFirestore();

  // holds the goal type annotations to append to those types (usually the UOM)
  const goalTypeAnnotations = useRef<{[key: string]: string}>({});

  // tracker state
  const [trackerId, setTrackerId] = useState('');
  const [trackerName, setTrackerName] = useState('');
  const [trackerDescription, setTrackerDescription] = useState('');
  const [filterConfigs, setFilterConfigs] = useState<TypeFilterConfig[]>([]);
  const [templates, setTemplates] = useState<any[]>([]);
  const [currTemplate, setCurrTemplate] = useState<any>();
  const [currTemplateId, setCurrTemplateId] = useState('');
  const [domainName, setDomainName] = useState('');
  useEffect(() => {
    if (props.location && props.location.state && props.location.state.data) {
      const t = props.location.state.data;
      if (typeof t.name === 'string' && t.name.length > 0) {
        setTrackerName(t.name);
      }
      if (typeof t.description === 'string' && t.description.length > 0) {
        setTrackerDescription(t.description);
      }
      if (typeof t.id === 'string' && t.id.length > 0) {
        setTrackerId(t.id);
        // load the filters
        (async () => {
          const tSnap = await getDoc(doc(db, `Tracker/${t.id}`));
          const fetchedName = tSnap.get('name');
          if (fetchedName != null && fetchedName != t.name) {
              setTrackerName(tSnap.get('name'));
          }
          // check if the fetched description is different than the cached one we loaded
          const fetchedDes = tSnap.get("description");
          if (fetchedDes != null && fetchedDes != t.description) {
              setTrackerDescription(tSnap.get("description"));
          }

          loadFilterConfigs(tSnap.get('filters') ?? {}, setFilterConfigs);

          const templateIds = tSnap.get('trackerTemplateIds');
          if (Array.isArray(templateIds) && templateIds.length > 0) {
            const tempTemplates: any[] = [];
            // don't include 'statistics' template, that one is explicitly handled below
            for (const id of templateIds.filter(id => id !== statisticsTemplateId)) {
              const template = await fetchTrackerTemplate(id);
              if (template && template.exists()) {
                tempTemplates.push({...template.data(), id: template.id});
              }
            }
            setTemplates(tempTemplates);
            if (tempTemplates.length > 0) {
              setCurrTemplate(tempTemplates[0]);
              setCurrTemplateId(tempTemplates[0].id ?? '');
            }
          }

          const goalTypeAttrSnap = await getDoc(doc(db, 'Attribute/goalType'));
          const enums = goalTypeAttrSnap.get('enums');
          if (Array.isArray(enums)) {
              enums.forEach(e => {
                  const name = e.display;
                  const annotation = e.annotation;
                  if (name && annotation) {
                      goalTypeAnnotations.current[name] = annotation;
                  }
              });
          }
        })();
      }

      // load the domain name so we can remove it from user ids
      (async () => {
        const envSnap = await getDoc(doc(db, 'Environment/environment'));
        if (envSnap.exists() && typeof envSnap.get('domain') === 'string') {
          setDomainName(envSnap.get('domain'));
        }
      })();
    }
  }, [props.location]);

  ///////////////////////////
  // statistics data table state
  ///////////////////////////
  

  // get columns for the statistics tracker template
  const [statisticsCols, setStatisticsCols] = useState<GridColDef[]>([]);
  useEffect(() => {
    (async () => {
      const template = await fetchTrackerTemplate(statisticsTemplateId);
      if (!template) return;
      const cs = template.get('columns');
      if (!Array.isArray(cs)) {
        console.error(`Error: Tracker Template with ID '${statisticsTemplateId}' has malformed 'columns' field`);
        return;
      }

      const gridCols = parseDTCols(cs);
      setStatisticsCols(gridCols.sort((a, b) => a.sequence - b.sequence));
    })();
  }, [])

  const parseItem = (d: DocumentSnapshot<DocumentData>) => {
    let temp: any = {
      id: JSON.stringify(d.data()),
      ...d.data(),
    }
    const gtVal = d.get('goalType');
    if (goalTypeAnnotations && gtVal && goalTypeAnnotations.current.hasOwnProperty(gtVal)) {
      temp.goalType = temp.goalType + ' (' + goalTypeAnnotations.current[gtVal] + ')';
    }
    return temp;
  };

  // get rows (wait until 'cols' data is set)
  const [rows, setRows] = useState<any[]>([]);
  // paginated fetch state
  const unsubscribe = useRef<Unsubscribe>(() => void 0);
  const loading = useRef(false);
  useEffect(() => {
    if (typeof trackerId === 'string' && trackerId.length > 0) {
      fetchData({
        collectionName: `Tracker/${trackerId}/DataTables/summary/${statisticsTemplateId}`,
        countDoc: `Tracker/${trackerId}`,
        parseCount: (d) => d.get(`summary.dataTableCount`) ?? 0,
        setItems: setRows,
        loading: loading,
        unsubscribe: unsubscribe,
        parseItem: parseItem,
        orderByVal: 'goalType',
        ascending: true,
      })
    }
    // unsubscribe listener on cleanup
    return function cleanUp() {
      unsubscribe.current();
    }
  }, [statisticsCols]);

  const editTracker = () => {
    props.history.push({
			pathname: '/TrackerDetail',
			state: { data: {
        ...props.location.state.data,
        name: trackerName,
        description: trackerDescription
      }}
		});
  }
  
  return (
    <Paper classes={{root: style.root}}>
      {/* header */}
      <Box display="flex" alignItems='center' justifyContent='space-between' className={style.header}>
        <div className={style.leftRightBox}>
          <Button className={style.backButton} onClick={() => props.history.goBack()}>
            <ArrowBack color="primary"/>
          </Button>
        </div>
        <Typography variant='h4' className={style.title}>
          {trackerName}
        </Typography>
        <div className={style.leftRightBox}>
          <Button className={style.editButton} onClick={editTracker}>
            <Typography color="primary" variant='body1' className={style.editButtonText}>
              Edit
            </Typography>
          </Button>
        </div>
      </Box>
      <div className={style.body}>
        {/* description */}
        <div className={style.descriptionContainer}>
          <Typography color="secondary" variant='body1'>
            {trackerDescription}
          </Typography>
        </div>

        {/* show filters */}
        <FilterSection
          title={'Filters'}
          filters={filterConfigs}
        />

        {/* datatables */}
        {templates.length > 0 &&
        <ContentHeader
          content={currTemplate ? 
            <TemplateDataTable 
              trackerId={trackerId}
              template={currTemplate}
              domain={domainName}
              goalTypeAnnotations={goalTypeAnnotations}
            />
            : null
          }
          customHeader={
            <FormControl>
              <Select
                color='secondary'
                label='Datatable'
                value={currTemplateId}
                disabled={templates.length < 2}
                disableUnderline
                renderValue={() => <Typography color='secondary' variant='h6'>
                  {currTemplate.name ?? ''}
                </Typography>}
                onChange={e => {
                  const selected = templates.find(t => t.id === e.target.value);
                  if (e.target.value && selected) {
                    setCurrTemplate(selected);
                    setCurrTemplateId(selected.id);
                  }
                }}
              >
                {templates.map(t => <MenuItem
                  key={`template-${JSON.stringify(t)}`}
                  value={t.id}
                >
                  {t.name}
                </MenuItem>)}
              </Select>
            </FormControl>
          }
        />
        }

        <ContentHeader
          title='Statistics'
          content={<DataGrid
            classes={{ root: style.datagridRoot, columnHeader: style.datagridHeader }}
            rows={rows}
            columns={statisticsCols}
            disableSelectionOnClick
            loading={loading.current}
            disableColumnMenu
            hideFooter
            headerHeight={40}
            autoHeight
            showColumnRightBorder
            sortingOrder={['asc', 'desc']}
          />}
        />
        <SysInfo trackerId={trackerId} />
      </div>
    </Paper>
  );
}

export default TrackerMetricsPage;

const headerHeight = '80px';
const styles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 1048,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  datagridContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    height: '500px',
    width: '96.5%',
    marginBottom: '40px'
  },
  datagridRoot: {
    width: '96.5%',
    color: '#455A64',
    marginBottom: '40px',
    overflowX: 'hidden'
  },
  datagridHeader: {
    fontSize: 15,
    backgroundColor: '#ECEFF1',
    color: '#455A64'
  },
  header: {
    position: 'fixed',
    zIndex: 3,
    width: 1048,
    backgroundColor: 'white',
    height: headerHeight,
    border: '2px solid #ededed',
    marginLeft: '-2px',
    borderRadius: '2px'
  },
  body: {
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    paddingTop: headerHeight
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#455A64'
  },
  descriptionContainer: {
    display: 'flex',
    margin: '8px 8px 24px 8px',
  },
  editButton: {
    minHeight: '60px'
  },
  editButtonText: {
    fontWeight: 600
  },
  backButton: {
    minHeight: '60px',
    minWidth: '60px'
  },
  leftRightBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 80,
    height: '100%',
  },
  contentHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%'
  },
  contentHeaderTitle: {
    width: '100%',
    padding: '2px 0px 20px 40px'
  },
  white: {
    color: 'white'
  },
  percentBar: {
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    wrap: 'nowrap',
    overflow: 'hidden',
    borderRadius: '4px',
    border: '1px solid #ededed',
    padding: 0
  },
  percentBarComplete: {
    height: '100%',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  percentBarText: {
    color: '#455A64',
    position: 'absolute',
    textAlign: 'center',
    overflow: 'hidden',
  }
}));
