/********************************************************
 * File: AccountFiltersSection.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-08-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useEffect, useRef, useState } from 'react';
import { 
  map as loMap, get as loGet, last as loLast, assign as loAssign
} from 'lodash';
import { FilterSection, TypeFilterConfig } from '../core/FilterSection';
import { GridColDef } from '@material-ui/data-grid';
import { SelectorGrid } from '../core/ItemSelectorGrid';
import { TypeTracker } from '../../typings/tracker.types';
import {
  getFirestore, collection, onSnapshot, DocumentData, query, where, getDocs,
  DocumentSnapshot
} from 'firebase/firestore';
import HierarchicalSelectorMenu from '../../components/core/HierarchicalSelectorMenu';

// Account selector grid columns
const maxWidth = 845;
const accountCols: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: maxWidth*.2, type: 'number' },
  { field: 'name', headerName: 'Name', width: maxWidth*.4 },
  { field: 'org', headerName: 'Org', width: maxWidth*.4 },
];

export const parseOrgDoc = (
  doc: DocumentSnapshot<DocumentData>,
  orgLevels: React.MutableRefObject<{[key: number]: any}>
) => {
  // replace the level number with the display name
  const lvl = doc.get('orgLevel') ?? '';
  let scope = lvl;
  if (typeof lvl === 'number' && Object.keys(orgLevels.current).length > 0) {
    scope = orgLevels.current[lvl].display ?? orgLevels.current[lvl].value ?? '';
  }
  let key = doc.get('key');
  if (typeof key === 'string' && key.length > 0) {
    // lowercase the first letter
    key = key[0].toLowerCase() + key.slice(1);
  } else key = '';
  return {
    value: doc.id,
    key: key,
    display: doc.get('display') ?? '',
    scope: scope,
    label: `${doc.get('display') ?? ''}: ${doc.id}`
  };
}

// used for data table
const parseAccountData = (t: DocumentData) => {
  // get date and convert to JS Date
  let ua = t.get('updatedAt') ?? null;
  if (ua != null) ua = ua.toDate();

  let orgs = t.get('org') ?? '';
  if (Array.isArray(orgs) && orgs.length > 0) {
    orgs = orgs.map(o => o.id ?? '').join(', ');
  }

  // get other fields in the return
  return ({
    id: t.get('id') ?? t.id,
    name: t.get('name') ?? '',
    org: orgs,
    explicit: t.get('isExplicit') ?? ''
  });
}

type TypeAccountFiltersSectionProps = {
  tracker: TypeTracker;
  orgLevels: React.MutableRefObject<{[key: number]: any}>;
  attributes: any[];
  setAttributes: React.Dispatch<React.SetStateAction<any[]>>;
  excludeAttributes: any[];
  setExcludeAttributes: React.Dispatch<React.SetStateAction<any[]>>;
  orgs: any[];
  setOrgs: React.Dispatch<React.SetStateAction<any[]>>;
  excludeOrgs: any[];
  setExcludeOrgs: React.Dispatch<React.SetStateAction<any[]>>;
  setIsStateDirty: React.Dispatch<React.SetStateAction<boolean>>;
}


function AccountFiltersSection({
  tracker,
  orgLevels,
  attributes,
  setAttributes,
  excludeAttributes,
  setExcludeAttributes,
  orgs,
  setOrgs,
  excludeOrgs,
  setExcludeOrgs,
  setIsStateDirty
}: TypeAccountFiltersSectionProps) {
  const db = getFirestore();

  //////////////////////
  // HIERARCHY SELECTORS
  //////////////////////

  /////////
  // attributes selector
  const [allAttributes, setAllAttributes] = useState<any[]>([]);
  const loadingAttributes = useRef(false);
  const delegate = async (selectedPath: any[], selectedOptions: any[], close:any) => {
    if (selectedPath.length === 0) {
      // Nothing has been drilled, show initial list
      return loMap(allAttributes, (entry, index) => ({
        index: index,
        value: entry.id,
        display: entry.display,
        key: entry.key,
        chevron: true
      }));
    }

    // One item has been drilled, so show the enums for it.
    const selectedIndex = loGet(loLast(selectedPath), 'index')
    return loMap(loGet(allAttributes, `${selectedIndex}.enums`), entry => ({
      value: entry.value,
      display: entry.display,
      chevron: false
    }));
  }

  // load account attributes on attach
  useEffect(() => {
    loadingAttributes.current = true;
    return onSnapshot(
      query(collection(db, "Attribute"), where("collection", "==", "Account")),
      snap => {
        loadingAttributes.current = false;
        setAllAttributes(loMap(snap.docs, doc => loAssign(doc.data(), { id: doc.id })))
      }
    );
  }, []);

  const transformSelection = (selectedPath: any[], selectedOption: any) => ({
    key: selectedPath[0]?.key ?? '',
    value: selectedOption.value,
    display: selectedOption.display ?? selectedOption.value,
    scope: selectedPath[0]?.display ?? '',
    label: selectedOption.display ?? selectedOption.value
  });

  const accountAttributeSelector = (
    <HierarchicalSelectorMenu
      delegate={delegate}
      selected={attributes}
      setSelected={setAttributes}
      transformSelection={transformSelection}
      loading={loadingAttributes}
      isMultiSelect
      leafOnly
    />
  );

  /////////
  // exclude attributes selector
  // use same attribute list loaded in the include attribute selector, and same delegate

  const excludeAccountAttributeSelector = (
    <HierarchicalSelectorMenu
      delegate={delegate}
      selected={excludeAttributes}
      setSelected={setExcludeAttributes}
      transformSelection={transformSelection}
      loading={loadingAttributes}
      isMultiSelect
      leafOnly
    />
  );

  //////////
  // org selector
  const loadingOrgs = useRef(false);

  // map parent IDs => array of children
  // NOTE: the parentId for level 0 will be 'ROOT' even though there isn't a parent
  type Cache = {
    [parentId: string]: any[]
  }

  const orgsCache = useRef<Cache>({'ROOT': []});
  const orgsCacheInitialized = useRef(false);

  const orgDelegate = async (selectedPath: any[], selectedOptions: any[], close:any) => {
    loadingOrgs.current = true;
    const lvl = selectedPath.length + 1;
    const parentId = lvl === 1 ? 'ROOT' : loLast(selectedPath).value;

    // check if its cached
    if (orgsCache.current.hasOwnProperty(parentId)) {
      loadingOrgs.current = false;
      return orgsCache.current[parentId].map((c: any, idx: number) => { return {
        ...c,
        index: idx,
        chevron: lvl >= Object.keys(orgLevels.current).length ? false : true
      }});
    } else { // fetch if not cached
      const childrenQuery = query(collection(db, 'Org'), where('parentValue', '==', parentId));
      const orgs = await getDocs(childrenQuery);
      orgsCache.current[parentId] = [];
      orgs.docs.forEach(doc => {
        const org = parseOrgDoc(doc, orgLevels);
        orgsCache.current[parentId].push(org); // add org to cache!
      });
      loadingOrgs.current = false;
      return orgsCache.current[parentId].map((c: any, idx: number) => { return {
        ...c,
        index: idx,
        chevron: lvl >= Object.keys(orgLevels.current).length ? false : true 
      }});
    }
  }

  // load root org(s) on attach (just level 1)
  useEffect(() => {
    // get level 1 orgs
    if (!orgsCacheInitialized.current) {
      orgsCacheInitialized.current = true;
      return onSnapshot(
        query(collection(db, "Org"), where("orgLevel", "==", 1)),
        snap => {
          snap.docs.forEach(doc => {
            const org = parseOrgDoc(doc, orgLevels);
            orgsCache.current['ROOT'].push(org); // add org to cache!
          });
        }
      );
    }
  }, []);

  const orgSelector = (
    <HierarchicalSelectorMenu
      delegate={orgDelegate}
      selected={orgs}
      setSelected={setOrgs}
      loading={loadingOrgs}
      isMultiSelect
      hierarchical
    />
  );

  /////////
  // exclude orgs selector
  // reuse include delegate, loaded data

  const excludeOrgSelector = (
    <HierarchicalSelectorMenu
      delegate={orgDelegate}
      selected={excludeOrgs}
      setSelected={setExcludeOrgs}
      loading={loadingOrgs}
      isMultiSelect
      hierarchical
    />
  );

  //////////////////
  // FILTER CONFIG
  //////////////////
  const accountFilters: TypeFilterConfig[] = [
    {
      name: 'Orgs',
      selected: orgs,
      setSelected: setOrgs,
      hierarchySelector: orgSelector,
      chipColorGroup: 'account'
    },
    {
      name: 'Exclude Orgs',
      selected: excludeOrgs,
      setSelected: setExcludeOrgs,
      hierarchySelector: excludeOrgSelector,
      chipColorGroup: 'account'
    },
    {
      name: 'Account Attributes',
      selected: attributes,
      setSelected: setAttributes,
      hierarchySelector: accountAttributeSelector,
      chipColorGroup: 'account'
    },
    {
      name: 'Exclude Account Attributes',
      selected: excludeAttributes,
      setSelected: setExcludeAttributes,
      hierarchySelector: excludeAccountAttributeSelector,
      chipColorGroup: 'account'
    },
  ]

  return (
    <FilterSection
      title='Goal-Account Filters'
      filters={accountFilters}
      gridSelector={(
        <SelectorGrid
          trackerId={tracker.id ?? ''}
          itemType='Account'
          cols={accountCols}
          parseItemData={parseAccountData}
          setIsStateDirty={setIsStateDirty}
        />
      )}
    />
  );
}

export default AccountFiltersSection;