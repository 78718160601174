/********************************************************
 * File: constants.tsx
 * Project: @liquid-perform/mc
 * File Created: 08-19-2021
 * Author: Jenny Mistry (jenny@liquidanalytics.com)
 * 
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import { TypeAppBarContextualActions } from "typings/component.types";
import { Settings, Add, Delete, Edit, ExitToApp } from "@material-ui/icons";

/** Navigation Map */
export const CompItems = Object.freeze({    
    List: {
        PAGE_ID: "TrackerList",
        TITLE: "Tracker",
        PATH: "/TrackerList"
    },
    TrackerDetail: {
      PAGE_ID: "TrackerDetail",
      "TITLE": "Tracker",
      PATH: "/TrackerDetail"
    },
    TrackerMetrics: {
        PAGE_ID: "TrackerMetrics",
        TITLE: "Tracker",
        PATH: "/TrackerMetrics"
    }
  });

/**
 * CONTEXTUAL ACTIONS
 */
 export const ContextActionId = {
    /** Contextual Actions */
    NONE: "NO-OP++",
    ADD: "Add++",
    UPDATE: "UPDATE++",
    SAVE: "Save++",
    DELETE: "Delete++",
    CLOSE: "Close++",
    /** Appbar Menu actions */
    PROFILE: "mc_Profile",
    LOGOUT: "mc_Logout",
  };


/** AppBar Actions */

const AppBarProfile: TypeAppBarContextualActions = {
    id: ContextActionId.PROFILE,
    label: "My Profile",
    "aria-label": "Profile",
    "aria-haspopup": "true",
    color: "inherit",
    Icon: Settings
  };

  const LogoutAction: TypeAppBarContextualActions = {
    id: ContextActionId.LOGOUT,
    label: "Logout",
    "aria-label": "Logout",
    "aria-haspopup": "false",
    color: "inherit",
    Icon: ExitToApp,
}

/** Tag Manager Menu Actions */

// Category
const AddCategory: TypeAppBarContextualActions = {
  id: ContextActionId.ADD,
  label: "Add Category",
  "aria-label": "Add",
  "aria-haspopup": "true",
  color: "inherit",
  Icon: Add,
};

const UpdateCategory: TypeAppBarContextualActions = {
  id: ContextActionId.UPDATE,
  label: "Update Category",
  "aria-label": "Update",
  "aria-haspopup": "true",
  color: "inherit",
  Icon: Edit,
};

const DeleteCategory: TypeAppBarContextualActions = {
  id: ContextActionId.DELETE,
  label: "Delete Category",
  "aria-label": "Delete",
  "aria-haspopup": "true",
  color: "inherit",
  Icon: Delete,
};

// Scope
const AddScope: TypeAppBarContextualActions = {
  id: ContextActionId.ADD,
  label: "Add Scope",
  "aria-label": "Add",
  "aria-haspopup": "true",
  color: "inherit",
  Icon: Add,
};

const UpdateScope: TypeAppBarContextualActions = {
  id: ContextActionId.UPDATE,
  label: "Update Scope",
  "aria-label": "Update",
  "aria-haspopup": "true",
  color: "inherit",
  Icon: Edit,
};

const DeleteScope: TypeAppBarContextualActions = {
  id: ContextActionId.DELETE,
  label: "Delete Scope",
  "aria-label": "Delete",
  "aria-haspopup": "true",
  color: "inherit",
  Icon: Delete,
};

/** AppBar menu collection */
export const AppBarMenuActions: TypeAppBarContextualActions[] = [
  //AppBarProfile,
  LogoutAction
];

  
export const AppBarProfileMenuActions: TypeAppBarContextualActions[] = [
  AppBarProfile
];

/** Tag Manager Menu Actions */
export const CategoryMenuActions: TypeAppBarContextualActions[] = [
  AddCategory, UpdateCategory, DeleteCategory
];

export const ScopeMenuActions: TypeAppBarContextualActions[] = [
  AddScope, UpdateScope, DeleteScope
];

/** Items */
export namespace Collection {
  export enum Items {
    CATEGORY = "category",
    SCOPE = "scope",
    TAGS = "tags"  
  }
}

export const Status = [
  {
    id: "Active", 
    value: 'all.status.active'
  },
  {
    id: "Inactive", 
    value: 'all.status.inactive'
  },
  {
    id: "Suspended", 
    value: 'all.status.suspended'
  },
  {
    id: "Locked", 
    value: 'all.status.locked'
  },
  {
    id: "Hold", 
    value: 'all.status.hold'
  },
  {
    id: "WorkInProgress", 
    value: 'all.status.workinprogress'
  },
];


