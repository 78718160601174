import {
  getFirestore, collection, getDoc, doc, updateDoc,
  DocumentSnapshot, setDoc
} from 'firebase/firestore';
import { set as loSet, isEmpty } from 'lodash';
import { getAuth } from '@firebase/auth';
import { TypeTracker } from 'typings/tracker.types';
import { GoalPeriod } from '../components/pages/TrackerDetailPage';

type TypeSaveTrackerProps = {
  saveCalled: React.MutableRefObject<boolean>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStateDirty: React.Dispatch<React.SetStateAction<boolean>>;
  tracker: TypeTracker,
  setTracker: React.Dispatch<React.SetStateAction<TypeTracker>>;
  isPrivate: boolean,
  goalAttributes: any[];
  excludeGoalAttributes: any[];
  orgs: any[];
  excludeOrgs: any[];
  phs: any[];
  excludePhs: any[];
  accountAttributes: any[];
  excludeAccountAttributes: any[];
  productAttributes: any[];
  excludeProductAttributes: any[];
  goalPeriod: GoalPeriod[];
  name: string;
  description: string;
  trackerTemplates: any[];
  setSaveSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  openPopover?: (message: string, onOk?: (() => void), onCancel?: (() => void)) => void;
  fsDb?: any;
  fsAuth?: any;
}
export const saveTracker = async ({
  saveCalled,
  setIsSaving,
  setIsStateDirty,
  tracker,
  setTracker,
  isPrivate,
  goalAttributes,
  excludeGoalAttributes,
  orgs,
  excludeOrgs,
  phs,
  excludePhs,
  accountAttributes,
  excludeAccountAttributes,
  productAttributes,
  excludeProductAttributes,
  goalPeriod,
  name,
  description,
  trackerTemplates,
  setSaveSuccess,
  openPopover,
  fsDb,
  fsAuth
}: TypeSaveTrackerProps) => {
  // set indicator showing save has been called at least once
  if (!saveCalled.current) saveCalled.current = true;
  setIsSaving(true);

  if (isEmpty(name)) {
      setIsSaving(false);
      openPopover && openPopover('You must provide a name for this Tracker!');
      console.warn('Failed to save tracker: no name');
      return;
  }

  const db = fsDb ? fsDb : getFirestore();
  const auth = fsAuth ? fsAuth : getAuth();

  // check if updating or creating new tracker
  let tSnap: DocumentSnapshot | undefined;
  if (!isEmpty(tracker.id)) tSnap = await getDoc(doc(db, `Tracker/${tracker.id}`));
  const updating = (tSnap && tSnap.exists()) ? true : false;

  const d = new Date();
  const t = {
      updatedAt: d,
      updatedBy: auth.currentUser?.email ?? 'unknown',
      status: 'active',
      private: isPrivate,
      filters: {
          account: accountAttributes.map(a => ({ // include filters
              key: a.key,
              operator: 'OR', // TODO: when to use 'AND'?
              value: a.value,
              display: a.label,
              keyDisplay: a.scope
          })).concat(excludeAccountAttributes.map(a => ({ // concat the excludes
              key: a.key,
              operator: 'NOT',
              value: a.value,
              display: a.label,
              keyDisplay: a.scope
          }))),
          product: productAttributes.map(a => ({
              key: a.key,
              operator: 'OR', // TODO: when to use 'AND'?
              value: a.value,
              display: a.label,
              keyDisplay: a.scope
          })).concat(excludeProductAttributes.map(a => ({ // concat the excludes
              key: a.key,
              operator: 'NOT',
              value: a.value,
              display: a.label,
              keyDisplay: a.scope
          }))),
          org: orgs.map(a => ({
              key: a.key,
              operator: 'OR', // TODO: when to use 'AND'?
              value: a.value,
              display: a.display,
              keyDisplay: a.scope
          })).concat(excludeOrgs.map(a => ({ // concat the excludes
              key: a.key,
              operator: 'NOT',
              value: a.value,
              display: a.display,
              keyDisplay: a.scope
          }))),
          ph: phs.map(a => ({
              key: a.key,
              operator: 'OR', // TODO: when to use 'AND'?
              value: a.value,
              display: a.display,
              keyDisplay: a.scope
          })).concat(excludePhs.map(a => ({ // concat the excludes
              key: a.key,
              operator: 'NOT',
              value: a.value,
              display: a.display,
              keyDisplay: a.scope
          }))),
          goal: goalAttributes.map(a => ({
              key: a.key,
              operator: 'OR', // TODO: when to use 'AND'?
              value: a.value,
              display: a.label,
              keyDisplay: a.scope
          })).concat(excludeGoalAttributes.map(a => ({ // concat the excludes
              key: a.key,
              operator: 'NOT',
              value: a.value,
              display: a.label,
              keyDisplay: a.scope
          }))),
          goalStartDate: goalPeriod[0].startDate,
          goalEndDate: goalPeriod[0].endDate
      }
  }
  if (!isEmpty(name)) loSet(t, 'name', name);
  if (!isEmpty(description)) loSet(t, 'description', description);
  loSet(t, 'trackerTemplateIds', trackerTemplates.map(t => t.id));

  // tSnap will always be defined if we are updating... just need to make TS happy
  if (updating && typeof tSnap !== 'undefined') {
      try {
          updateDoc(tSnap.ref, t);
          setIsStateDirty(false);
      } catch (e) {
          setSaveSuccess(false);
          console.error('failed to update tracker');
          console.error(e);
      }
  } else { // doc doesn't exist, we are creating a new tracker
      const newTrackerRef = doc(collection(db, 'Tracker'));
      loSet(t, 'id', newTrackerRef.id);
      loSet(t, 'createdBy', auth.currentUser?.email ?? 'unknown');
      loSet(t, 'createdAt', d);
      try {
          setDoc(newTrackerRef, t);
          setTracker({...tracker, id: newTrackerRef.id});
          setIsStateDirty(false);
      } catch (e) {
          setSaveSuccess(false);
          console.error('failed to create tracker');
          console.error(e);
      }
  }
  setIsSaving(false);
}