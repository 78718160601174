/********************************************************
 * File: ProductFiltersSection.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-08-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useEffect, useState, useRef } from 'react';
import { map as loMap, get as loGet, last as loLast, assign as loAssign } from 'lodash';
import { FilterSection, TypeFilterConfig } from '../core/FilterSection';
import { GridColDef } from '@material-ui/data-grid';
import { SelectorGrid } from '../core/ItemSelectorGrid';
import { TypeTracker } from '../../typings/tracker.types';
import {
  getFirestore, collection, onSnapshot, DocumentData, query, where, getDocs, DocumentSnapshot
} from 'firebase/firestore';
import HierarchicalSelectorMenu from '../../components/core/HierarchicalSelectorMenu';

// Product selector grid columns
const maxWidth = 845;
const productCols: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: maxWidth*.2, type: 'number' },
  { field: 'name', headerName: 'Name', width: maxWidth*.4 },
  { field: 'ph', headerName: 'Ph', width: maxWidth*.4 },
];

const parseProductData = (t: DocumentData) => {
  // get date and convert to JS Date
  let ua = t.get('updatedAt') ?? null;
  if (ua != null) ua = ua.toDate();

  // get other fields in the return
  return ({
    id: t.get('id') ?? t.id,
    name: t.get('name') ?? '',
    ph: t.get('ph') ? (t.get('ph').id ?? '') : '',
    explicit: t.get('isExplicit') ?? false
  });
}

type TypeProductFiltersSectionProps = {
  tracker: TypeTracker;
  phLevels: React.MutableRefObject<{[key: number]: any}>;
  attributes: any[];
  setAttributes: React.Dispatch<React.SetStateAction<any[]>>;
  excludeAttributes: any[];
  setExcludeAttributes: React.Dispatch<React.SetStateAction<any[]>>;
  phs: any[];
  setPhs: React.Dispatch<React.SetStateAction<any[]>>;
  excludePhs: any[];
  setExcludePhs: React.Dispatch<React.SetStateAction<any[]>>;
  setIsStateDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

function ProductFiltersSection({
  tracker,
  phLevels,
  attributes,
  setAttributes,
  excludeAttributes,
  setExcludeAttributes,
  phs,
  setPhs,
  excludePhs,
  setExcludePhs,
  setIsStateDirty
}: TypeProductFiltersSectionProps) {
  const db = getFirestore();

  //////////////////////
  // HIERARCHY SELECTORS
  //////////////////////

  /////////
  // attributes selector
  const [allAttributes, setAllAttributes] = useState<any[]>([]);
  const loadingAttributes = useRef(false);
  const delegate = async (selectedPath: any[], selectedOptions: any[], close:any) => {
    if (selectedPath.length === 0) {
      // Nothing has been drilled, show initial list
      return loMap(allAttributes, (entry, index) => ({
        index: index,
        value: entry.id,
        display: entry.display,
        key: entry.key,
        chevron: true
      }));
    }

    // One item has been drilled, so show the enums for it.
    const selectedIndex = loGet(loLast(selectedPath), 'index')
    return loMap(loGet(allAttributes, `${selectedIndex}.enums`), entry => ({
      value:entry.value,
      display: entry.display,
      chevron: false
    }));
  }

  // load product attributes on attach
  useEffect(() => {
    loadingAttributes.current = true;
    return onSnapshot(
      query(collection(db, "Attribute"), where("collection", "==", "Product")),
      snap => {
        loadingAttributes.current = false;
        setAllAttributes(loMap(snap.docs, doc => loAssign(doc.data(), { id: doc.id })))
      }
    );
  }, []);

  const transformSelection = (selectedPath: any[], selectedOption: any) => ({
    key: selectedPath[0]?.key ?? '',
    value: selectedOption.value,
    display: selectedOption.display ?? selectedOption.value,
    scope: selectedPath[0]?.display ?? '',
    label: selectedOption.display ?? selectedOption.value
  })

  const productAttributeSelector = (
    <HierarchicalSelectorMenu
      delegate={delegate}
      selected={attributes}
      setSelected={setAttributes}
      transformSelection={transformSelection}
      loading={loadingAttributes}
      isMultiSelect
      leafOnly
    />
  );

  /////////
  // exclude attributes selector
  // reuse the include delegate, loaded data

  const excludeProductAttributeSelector = (
    <HierarchicalSelectorMenu
      delegate={delegate}
      selected={excludeAttributes}
      setSelected={setExcludeAttributes}
      transformSelection={transformSelection}
      loading={loadingAttributes}
      isMultiSelect
      leafOnly
    />
  );

  //////////
  // ph selector
  const loadingPhs = useRef(false);
  const parsePhDoc = (doc: DocumentSnapshot<DocumentData>) => {
    // replace the level number with the display name
    const lvl = doc.get('phLevel') ?? '';
    let scope = lvl;
    if (typeof lvl === 'number' && Object.keys(phLevels.current).length > 0) {
      scope = phLevels.current[lvl].display ?? phLevels.current[lvl].value ?? '';
    }
    let key = doc.get('key');
    if (typeof key === 'string' && key.length > 0) {
      // lowercase the first letter
      key = key[0].toLowerCase() + key.slice(1);
    } else key = '';
    return {
      value: doc.id,
      key: key,
      display: doc.get('display') ?? '',
      scope: scope,
      label: `${doc.get('display') ?? ''}: ${doc.id}`
    };
  }

  // map parent IDs => array of children
  // NOTE: the parentId for level 0 will be 'ROOT' even though there isn't a parent
  type Cache = {
    [parentId: string]: any[]
  }

  const phsCache = useRef<Cache>({'ROOT': []});
  const phsCacheInitialized = useRef(false);

  const phDelegate = async (selectedPath: any[], selectedOptions: any[], close:any) => {
    loadingPhs.current = true;
    const lvl = selectedPath.length + 1;
    const parentId = lvl === 1 ? '' : loLast(selectedPath).value;

    // check if its cached
    if (phsCache.current.hasOwnProperty(parentId)) {
      loadingPhs.current = false;
      return phsCache.current[parentId].map((c: any, idx: number) => { return {
        ...c,
        index: idx,
        chevron: lvl >= Object.keys(phLevels.current).length ? false : true
      }});
    } else { // fetch if not cached
      const childrenQuery = query(collection(db, 'Ph'), where('parentValue', '==', parentId));
      const phs = await getDocs(childrenQuery);
      phsCache.current[parentId] = [];
      phs.docs.forEach(doc => {
        const ph = parsePhDoc(doc);
        phsCache.current[parentId].push(ph); // add ph to cache!
      });
      loadingPhs.current = false;
      return phsCache.current[parentId].map((c, idx) => { return {
        ...c,
        index: idx,
        chevron: lvl >= Object.keys(phLevels.current).length ? false : true
      }});
    }
  }

  // load root ph(s) on attach (just level 1)
  useEffect(() => {
    // get level 1 phs
    if (!phsCacheInitialized.current) {
      phsCacheInitialized.current = true;
      return onSnapshot(
        query(collection(db, "Ph"), where("level", "==", 1)),
        snap => {
          snap.docs.forEach(doc => {
            const ph = parsePhDoc(doc);
            phsCache.current['ROOT'].push(ph); // add ph to cache!
          });
        }
      );
    }
  }, []);

  const phSelector = (
    <HierarchicalSelectorMenu
      delegate={phDelegate}
      selected={phs}
      setSelected={setPhs}
      loading={loadingPhs}
      isMultiSelect
      hierarchical
    />
  );

  //////////
  // exclude ph selector

  const excludePhSelector = (
    <HierarchicalSelectorMenu
      delegate={phDelegate}
      selected={excludePhs}
      setSelected={setExcludePhs}
      loading={loadingPhs}
      isMultiSelect
      hierarchical
    />
  );

  ////////////////
  // FILTER CONFIG
  ////////////////

  const productFilters: TypeFilterConfig[] = [
    {
      name: 'Product Hierarchies',
      selected: phs,
      setSelected: setPhs,
      hierarchySelector: phSelector,
      chipColorGroup: 'product'
    },
    {
      name: 'Exclude Product Hierarchies',
      selected: excludePhs,
      setSelected: setExcludePhs,
      hierarchySelector: excludePhSelector,
      chipColorGroup: 'product'
    },
    {
      name: 'Product Attributes',
      selected: attributes,
      setSelected: setAttributes,
      hierarchySelector: productAttributeSelector,
      chipColorGroup: 'product'
    },
    {
      name: 'Exclude Product Attributes',
      selected: excludeAttributes,
      setSelected: setExcludeAttributes,
      hierarchySelector: excludeProductAttributeSelector,
      chipColorGroup: 'product'
    },
  ]

  return (
    <FilterSection
      title='Goal-Product Filters'
      filters={productFilters}
      gridSelector={(
        <SelectorGrid
          trackerId={tracker.id ?? ''}
          itemType='Product'
          cols={productCols}
          parseItemData={parseProductData}
          setIsStateDirty={setIsStateDirty}
        />
      )}
    />
  )
}

export default ProductFiltersSection;