/********************************************************
 * File: TrackerDetailPage.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-01-2021
 * Author: Bryan Nagle
 * bryan.nagle@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
    Theme, Paper, Button, Box, TextField, Select, Popover,
    MenuItem, FormControlLabel, Checkbox, Input, CircularProgress,
    Typography, Menu, ListItemIcon,
    Chip, InputLabel
} from '@material-ui/core';
import { TypeTracker } from 'typings/tracker.types';
import {
    getFirestore, collection, onSnapshot, getDoc, doc, updateDoc, DocumentData,
    DocumentSnapshot, query, where
} from 'firebase/firestore';
import { getAuth } from '@firebase/auth';
import GoalFiltersSection from '../form/GoalFiltersSection';
// import AccountFiltersSection from '../form/AccountFiltersSection';
// import ProductFiltersSection from '../form/ProductFiltersSection';
import SysInfo from '../core/SysInfo';
import { AppTheme } from '../../Theme';
import { ArrowBack, Delete, Done, MoreVert, Warning, Equalizer } from '@material-ui/icons';
import ProductFiltersSection from '../form/ProductFiltersSection';
import AccountFiltersSection, { parseOrgDoc } from '../form/AccountFiltersSection';
import { saveTracker } from '../../api/saveTracker';
import { fetchTrackerTemplate } from '../../api/firestoreFetch';

// type compatible with the DateRange component
export type GoalPeriod = {
    startDate: Date | null,
    endDate: Date | null,
    key: string
}

type TypeDetailActionsMenuProps = {
    handleDelete: () => void;
    handleViewMetrics: () => void;
}

function DetailActionsMenu({ 
    handleDelete,
    handleViewMetrics
}: TypeDetailActionsMenuProps) {
    const style = styles(AppTheme);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    return (<>
        <Button className={style.headerButton} onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVert color='primary' fontSize='large'/>
        </Button>
        <Menu
            id='more-menu'
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            classes={{ list: style.menuList }}
        >
            {/* view metrics item */}
            <MenuItem className={style.menuItem} onClick={handleViewMetrics}>
                <ListItemIcon>
                    <Equalizer color='secondary'/>
                </ListItemIcon>
                <Typography color='secondary'>Data Table</Typography>
            </MenuItem>

            {/* delete tracker item */}
            <MenuItem className={style.menuItem} onClick={handleDelete}>
                <ListItemIcon>
                    <Delete color='secondary'/>
                </ListItemIcon>
                <Typography color='secondary'>Delete Tracker</Typography>
            </MenuItem>
        </Menu>
    </>)
}

function TrackerDetailPage(props: any) {
    const db = getFirestore();
    const auth = getAuth();

    // Style
    const style = styles(AppTheme);

    const listPage = () => props.history.push({
        pathname: '/TrackerList',
        state: { data: tracker }
    });

    const handleCancelClick = (event: any) => {
        if (isStateDirty) {
            openPopover(
                "Discard unsaved changes?",
                () => props.history.goBack(),
            );
        } else {
            props.history.goBack();
        }
    }

    const handleDeleteClick = () => {
        openPopover(
            "Delete this Tracker?",
            deleteTracker,
            () => void 0 // nothing to do
        );
    }

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverMessage, setPopoverMessage] = useState('');
    const [popoverOnOk, setPopoverOnOk] = useState<()=>void | undefined>();
    const [popoverOnCancel, setPopoverOnCancel] = useState<()=>void | undefined>();

    // states for showing save spinner, success indicator
    const [isSaving, setIsSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false);
    const saveCalled = useRef(false);

    const [loaded, setLoaded] = useState(false);
    const [isStateDirty, setIsStateDirty] = useState(false);

    // Data States
    const [tracker, setTracker] = useState({} as TypeTracker);
    const [name, setName] = useState('');
    const [allTemplates, setAllTemplates] = useState<any[]>([]);
    const [description, setDescription] = useState('');
    const [trackerTemplates, setTrackerTemplates] = useState<any[]>([]);
    // const [active, setActive] = useState(true);
    const [isPrivate, setIsPrivate] = useState(false);

    // load hierarchy levels and names
    const orgLevels = useRef<{[key: number]: any}>({});
    const phLevels = useRef<{[key: number]: any}>({});
    useEffect(() => {
        // org levels
        (async () => {
            const orgDoc = await getDoc(doc(db, 'Attribute/orgLevel'));
            if (!orgDoc.exists()) return void 0;
            const levels = orgDoc.get('enums');
            if (_.isNil(levels) || !Array.isArray(levels)) return void 0;
            const tempOrgMap: any = {};
            levels.forEach(lvl => {
                const val = +lvl.value; // try to convert the string to a number
                if (typeof val !== 'number') return void 0;
                const lvlFormatted = { ...lvl, value: val };
                tempOrgMap[lvl.value] = lvlFormatted;
            });
            orgLevels.current = tempOrgMap;
        })();

        // ph levels
        (async () => {
            const phDoc = await getDoc(doc(db, 'Attribute/phLevel'));
            if (!phDoc.exists()) return void 0;
            const levels = phDoc.get('enums');
            if (_.isNil(levels) || !Array.isArray(levels)) return void 0;
            const tempPhMap: any = {};
            levels.forEach(lvl => {
                const val = +lvl.value; // try to convert the string to a number
                if (typeof val !== 'number') return void 0;
                const lvlFormatted = { ...lvl, value: val };
                tempPhMap[val] = lvlFormatted;
            });
            phLevels.current = tempPhMap;
        })();
    }, [])

    //////////////////////////////
    // ITEM FILTER STATES
    //////////////////////////////
    // goals
    const [goalAttributes, setGoalAttributes] = useState<any[]>([]);
    const [excludeGoalAttributes, setExcludeGoalAttributes] = useState<any[]>([]);
    const [orgs, setOrgs] = useState<any[]>([]);
    // goal period format compatible with the DateRange component
    const [goalPeriod, setGoalPeriod] = useState<GoalPeriod[]>([{
        startDate: null,
        endDate: null,
        key: 'selection'
    }]);
    // accounts
    const [accountAttributes, setAccountAttributes] = useState<any[]>([]);
    const [excludeAccountAttributes, setExcludeAccountAttributes] = useState<any[]>([]);
    const [excludeOrgs, setExcludeOrgs] = useState<any[]>([]);
    // products
    const [productAttributes, setProductAttributes] = useState<any[]>([]);
    const [excludeProductAttributes, setExcludeProductAttributes] = useState<any[]>([]);
    const [phs, setPhs] = useState<any[]>([]);
    const [excludePhs, setExcludePhs] = useState<any[]>([]);


    // track whether data has changed (state is dirty) to enable save
    useEffect(() => {
        // only update dirty state once everything else is loaded so
        //   it isn't triggered by data loads
        if (loaded && !isStateDirty) {
            setIsStateDirty(true);
        }
    }, [
        name, trackerTemplates, description, isPrivate,
        goalAttributes, excludeGoalAttributes, accountAttributes, excludeAccountAttributes,
        orgs, excludeOrgs, productAttributes, excludeProductAttributes,
        phs, excludePhs, goalPeriod,
        // active
    ]);

    const deleteTracker = async () => {
        if (_.isEmpty(tracker.id)) {
            console.warn('Failed to delete tracker: no ID!');
            return;
        }

        const tSnap = await getDoc(doc(db, `Tracker/${tracker.id}`));
        if (!tSnap.exists()) {
            console.warn(`Failed to delete tracker with ID ${tracker.id}: document does not exist!`);
            return;
        }

        const info = await tSnap.get('info') ?? {};

        await updateDoc(tSnap.ref, { 
            status: 'delete', // logical delete
            info: {
                ...info,
                updatedAt: new Date(),
                updatedBy: auth.currentUser?.email ?? 'unknown user'
            }
        });
        listPage();
    }


    // Load Tracker Templates on Attach
    useEffect(() => {
        const q = query(collection(db, "DataTableTemplate"), where('type', '==', 'tracker'));
        return onSnapshot(q, (snap) => {
            setAllTemplates(_.map(snap.docs, doc => _.assign(doc.data(), { id: doc.id })));
        });
    }, []);

    const parseFilter = (f: DocumentData, concatVal: boolean) => {
        // default for both display and label is `display`, fall back to `value`
        //const disp = f.display ?? f.value;
        let label = f.display ?? f.value;
        // if concat (used for orgs and phs), concat the value to the label
        if (concatVal && f.display) {
            label = `${f.display}: ${f.value}`;
        }
        return ({
            key: f.key ?? '',
            value: f.value ?? '',
            display: f.display ?? '',
            scope: f.keyDisplay ?? f.key ?? '',
            label: label
        });
    }

    const itemSetterMap: {[key: string]: React.Dispatch<React.SetStateAction<any[]>>} = {
        account: setAccountAttributes,
        accountExclude: setExcludeAccountAttributes,
        org: setOrgs,
        orgExclude: setExcludeOrgs,
        product: setProductAttributes,
        productExclude: setExcludeProductAttributes,
        ph: setPhs,
        phExclude: setExcludePhs,
        goal: setGoalAttributes,
        goalExclude: setExcludeGoalAttributes
    }

    const parseItemFilters = (filters: any, item: string, tempTracker: any) => {
        const concatVal = ['ph', 'org'].includes(item);
        if (filters[item] && Array.isArray(filters[item])) {
            const fs = filters[item]
                .filter((f: any) => (typeof f.value === 'string') && ['AND', 'OR'].includes(f.operator))
                .map((f: any) => parseFilter(f, concatVal));
            tempTracker[`${item}Filters`] = fs;
            itemSetterMap[item](fs);

            const fsExclude = filters[item]
                .filter((f: any) => (typeof f.value === 'string') && f.operator === 'NOT')
                .map((f: any) => parseFilter(f, concatVal));
            tempTracker[`${item}ExcludeFilters`] = fsExclude;
            itemSetterMap[`${item}Exclude`](fsExclude);
        }
    }

    type TypeInfoPopoverProps = {
        message: string;
        onOk?: ()=>void;
        onCancel?: ()=>void;
    }

    function InfoPopover({ message, onOk, onCancel }: TypeInfoPopoverProps) {
        return (
            <Popover
                id='info-popover'
                open={popoverOpen}
                anchorReference='none'
                className={style.popover}
                onClose={() => setPopoverOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper className={style.popoverPaper}>
                    <Typography color="secondary" className={style.popoverMessage}>
                        {message}
                    </Typography>
                    <div className={style.popoverButtonRow}>
                        {onCancel && <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                                onCancel();
                                setPopoverOpen(false);
                            }}
                            className={style.popoverCancel}
                        >
                            Cancel
                        </Button>}
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                                onOk && onOk();
                                setPopoverOpen(false);
                            }}
                            className={style.popoverClose}
                        >
                            Ok
                        </Button>
                    </div>
                </Paper>
            </Popover>
        );
    }

    const openPopover = (message: string, onOk?: ()=>void, onCancel?: ()=>void) => {
        setPopoverMessage(message);
        setPopoverOnOk(onOk ? () => onOk : undefined);
        setPopoverOnCancel(onCancel ? () => onCancel : undefined);
        setPopoverOpen(true);
    }

    const saveComp = () => {
        // if saving show spinner
        if (isSaving) {
            return <CircularProgress color='secondary' />;
        } else {
            // if just finished saving, show success indicator, otherwise 'Save' text
            return showSuccess ? 
                (saveSuccess ? <Done className={style.successIcon}/> : <Warning color='error'/>)
                :
                'Save'
        }
    }

    const _viewMetrics = () => {
        props.history.push({
                pathname: '/TrackerMetrics',
                state: { data: tracker },
            });
    }
    const viewMetrics = () => {
        if (isStateDirty) {
            openPopover(
                "Discard unsaved changes?",
                _viewMetrics,
                () => void 0 // nothing to do
            );
        } else {
            _viewMetrics();
        }
    }

    // when finished saving, show the success indicator for a few seconds
    // also reload the system info
    useEffect(() => {
        // if saving flag was turned off, and save has been called at least once
        if (!isSaving && saveCalled.current) {
            setShowSuccess(true);
            setTimeout(
                () => {
                    setShowSuccess(false);
                    if (!saveSuccess) setSaveSuccess(true);
                },
                3 * 1000 // 3 seconds
            );
        }
    }, [isSaving])

    const loadTemplates = async (tSnap: DocumentSnapshot<DocumentData>) => {
        const tids = tSnap.get('trackerTemplateIds') ?? null;
        if (tids != null) {
            const tempTemplates: any[] = [];
            await Promise.allSettled(tids.map(async (tid: string) => {
                const templateSnap = await fetchTrackerTemplate(tid);
                templateSnap && tempTemplates.push({...templateSnap.data(), id: templateSnap.id});
            }));
            if (tempTemplates.length > 0) {
                setTrackerTemplates(tempTemplates);
            }
        }
        return;
    }

    const loadFilters = (tSnap: DocumentSnapshot<DocumentData>, cachedTracker: any) => {
        const filters = tSnap.get('filters') ?? null;
        if (filters != null) {
            const temp: TypeTracker = {...cachedTracker};
            parseItemFilters(filters, 'account', temp);
            parseItemFilters(filters, 'product', temp);
            parseItemFilters(filters, 'org', temp);
            parseItemFilters(filters, 'ph', temp);
            parseItemFilters(filters, 'goal', temp);

            let tempGoalPeriod: GoalPeriod = {startDate: null, endDate: null, key: 'selection'};
            const gsd = filters['goalStartDate'];
            const ged = filters['goalEndDate'];
            tempGoalPeriod.startDate = gsd ? gsd.toDate() : null;
            tempGoalPeriod.endDate = ged ? ged.toDate() : null;
            setGoalPeriod([tempGoalPeriod]);
            setTracker(temp);
        }
        return;
    }
    
    // Store Tracker type received from list view, and load tracker Filters
    useEffect(() => {
        const t = _.get(props, 'location.state.data');
        if (_.isEmpty(t)) {
            listPage();
            return;
        };

        // for now use the state data; updated on fetch
        setTracker(t);
        setName(_.get(t, 'name', ''));
        setDescription(_.get(t, 'description', ''));

        // if the id is not set (adding a new tracker)
        if (!(typeof t.id === 'string' && t.id.length > 0)) {
            // set the org to the user's org
            (async () => {
                const userOrg = props.userOrg;
                if (typeof userOrg === 'string' && userOrg.length > 0) {
                    const orgDoc = await getDoc(doc(db, `Org/${userOrg}`));
                    if (orgDoc.exists()) {
                        setOrgs([parseOrgDoc(orgDoc, orgLevels)]);
                    }
                }
                setLoaded(true);
            })()
            return;
        }
        (async () => {
            const tSnap = await getDoc(doc(db, `Tracker/${t.id}`));
            setTracker({... tSnap.data(), id: tSnap.id});
            // check if the fetched name is different than the cached one we loaded
            const fetchedName = tSnap.get('name');
            if (fetchedName != null && fetchedName != _.get(t, 'name')) {
                setName(tSnap.get('name'));
            }
            // check if the fetched description is different than the cached one we loaded
            const fetchedDes = tSnap.get("description");
            if (fetchedDes != null && fetchedDes != _.get(t, 'description')) {
                setDescription(tSnap.get("description"));
            }
            if (typeof tSnap.get('private') === 'boolean') setIsPrivate(tSnap.get('private'));
            // if (typeof tSnap.get('active') === 'boolean') setActive(tSnap.get('active'));
            if (tSnap.exists()) {
                loadFilters(tSnap, t);
                await loadTemplates(tSnap);
            }
            setLoaded(true);
        })();
    }, [props.location]);

    return (<div>
        {!_.isEmpty(tracker) &&
            <Paper className={style.root} elevation={2}>
                <Box display="flex" className={style.Header}>
                    <div className={style.leftRightBox}>
                        <Button className={style.headerButton} onClick={handleCancelClick}>
                            <ArrowBack color='primary' />
                        </Button>
                    </div>
                    <Input
                        className={style.Title}
                        value={name}
                        onChange={(e) => setName(e.target.value ?? '')}
                        startAdornment={_.isEmpty(name) ? 
                            <div className={style.nameHelper}>Enter Name:</div> 
                            :
                            null
                        }
                        disableUnderline
                        autoFocus={!_.isEmpty(tracker) && _.isEmpty(name)}
                        color='primary'
                        inputProps={{ className: style.titleInput }}
                    />
                    <div className={style.leftRightBox}>
                        <Button
                            className={style.headerButton}
                            classes={{ text: style.saveText }}
                            onClick={() => saveTracker({
                                saveCalled,
                                setIsSaving,
                                setIsStateDirty,
                                openPopover,
                                tracker,
                                setTracker,
                                isPrivate,
                                goalAttributes,
                                excludeGoalAttributes,
                                orgs,
                                excludeOrgs,
                                phs,
                                excludePhs,
                                accountAttributes,
                                excludeAccountAttributes,
                                productAttributes,
                                excludeProductAttributes,
                                goalPeriod,
                                name,
                                description,
                                trackerTemplates,
                                setSaveSuccess
                            })}
                            disabled={!isStateDirty}
                        >
                            {saveComp()}
                        </Button>
                    </div>
                    <div className={style.leftRightBox}>
                        <DetailActionsMenu handleDelete={handleDeleteClick} handleViewMetrics={viewMetrics}/>
                    </div>
                </Box>

                <div className={style.body}>
                    <form className={style.Form}>
                        <Box color='secondary' className={style.rightPadding}>
                            <TextField 
                                variant="outlined"
                                fullWidth={true}
                                className={style.FormInput}
                                InputProps={{ 
                                    className: style.mainColor,
                                    classes: {
                                        notchedOutline: style.mainColor,
                                    }
                                }}
                                id="description"
                                label="Description"
                                multiline
                                maxRows={4}
                                value={description}
                                color='secondary'
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Box>
                        <Box display="flex" color='secondary' className={style.FormInput}>
                            {/* <FormControlLabel
                                className={style.rightMargin}
                                control={
                                    <Switch
                                        checked={active}
                                        onChange={(e, checked) => setActive(checked)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Active"
                            /> */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isPrivate}
                                        onChange={(e, checked) => setIsPrivate(checked)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Private"
                            />
                        </Box>
                        <Box color='secondary' className={style.selectBox}>
                            <InputLabel id='template-selections-label' className={style.mainColor} >
                                Datatable Templates
                            </InputLabel>
                            <Select
                                labelId='template-selections-label'
                                id='template-selections'
                                classes={{ root: style.selectRoot }}
                                multiple
                                variant='outlined'
                                fullWidth
                                value={trackerTemplates}
                                onOpen={() => setTrackerTemplates(trackerTemplates)}
                                onChange={e => {
                                    const vals: any[] = e.target.value as any[];
                                    const ids: string[] = vals.map(v => v.id); 
                                    if (vals) {
                                        setTrackerTemplates(allTemplates.filter(t => ids.includes(t.id)));
                                    } 
                                }}
                                MenuProps={{
                                    // fixes known bug with menu moving (https://github.com/mui-org/material-ui/issues/20707)
                                    getContentAnchorEl: () => null,
                                }}
                                renderValue={(selected) => Array.isArray(selected) ? (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gridGap: '5px' }}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value.id}
                                                label={value.name ?? ''}
                                                classes={{ label: style.mainColor }}
                                            />
                                        ))}
                                    </Box>
                                ) : null}
                            >
                                {allTemplates.map(t => {
                                    const sel = trackerTemplates.map(tt => tt.id).indexOf(t.id) > -1;
                                    return (<MenuItem 
                                        key={t.id}
                                        value={t}
                                        selected={sel}
                                    >
                                        <Checkbox 
                                            checked={sel}/>
                                        {t.name}
                                    </MenuItem>)
                                })}
                            </Select>
                        </Box>
                    </form>

                    <GoalFiltersSection
                        tracker={tracker}
                        isStateDirty={isStateDirty}
                        setIsStateDirty={setIsStateDirty}
                        attributes={goalAttributes}
                        setAttributes={setGoalAttributes}
                        excludeAttributes={excludeGoalAttributes}
                        setExcludeAttributes={setExcludeGoalAttributes}
                        goalPeriod={goalPeriod}
                        setGoalPeriod={setGoalPeriod}
                        userOrg={props.userOrg ?? ''}
                    />

                    <ProductFiltersSection
                        tracker={tracker}
                        phLevels={phLevels}
                        attributes={productAttributes}
                        setAttributes={setProductAttributes}
                        excludeAttributes={excludeProductAttributes}
                        setExcludeAttributes={setExcludeProductAttributes}
                        phs={phs}
                        setPhs={setPhs}
                        excludePhs={excludePhs}
                        setExcludePhs={setExcludePhs}
                        setIsStateDirty={setIsStateDirty}
                    />

                    <AccountFiltersSection
                        tracker={tracker}
                        orgLevels={orgLevels}
                        attributes={accountAttributes}
                        setAttributes={setAccountAttributes}
                        excludeAttributes={excludeAccountAttributes}
                        setExcludeAttributes={setExcludeAccountAttributes}
                        orgs={orgs}
                        setOrgs={setOrgs}
                        excludeOrgs={excludeOrgs}
                        setExcludeOrgs={setExcludeOrgs}
                        setIsStateDirty={setIsStateDirty}
                    />

                    <InfoPopover
                        message={popoverMessage}
                        onOk={popoverOnOk}
                        onCancel={popoverOnCancel}
                    />

                    <SysInfo trackerId={tracker.id} />
                </div>
            </Paper>
        }
    </div>

    )
}

export default TrackerDetailPage;

/** MUI STYLE */
const headerHeight = '80px';
const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: 1048,
        marginLeft: "auto",
        marginRight: "auto",
    },
    Title: {
        flexGrow: 1,
        fontSize: 30,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        cursor: 'text',
        marginLeft: 5,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingTop: headerHeight
    },
    titleInput: {
        color: '#455A64'
    },
    mainColor: {
        color: '#455A64',
        borderColor: '#455A64',
    },
    Header: {
        position: 'fixed',
        zIndex: 3,
        width: 1048,
        backgroundColor: 'white',
        border: '2px solid #ededed',
        marginLeft: '-2px',
        borderRadius: '2px',
        height: headerHeight,
    },
    leftRightBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '80px',
        minHeight: '80px',
    },
    headerButton: {
        minHeight: '62px',
        minWidth: '62px'
    },
    metricsButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    metricsButton: {
        height: '40px',
    },
    Form: {
        flexGrow: 1,
        margin: theme.spacing(1),
        padding: "10px",
    },
    FormInput: {
        margin: "10px 10px 20px 10px",
        color: '#455A64',
    },
    nameHelper: {
        color: '#455A64',
        marginRight: 10,
        fontStyle: 'italic'
    },
    popover: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    popoverPaper: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10
    },
    popoverButtonRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    popoverCancel: {
        marginRight: 10,
    },
    popoverClose: {
        marginLeft: 10,
    },
    popoverMessage: {
        display: 'flex',
        textAlign: 'center',
        padding: 20,
        fontSize: 20
    },
    successIcon: {
        color: 'green'
    },
    rightMargin: {
        marginRight: 40
    },
    rightPadding: {
        paddingRight: 30
    },
    menuList: {
        padding: 0,
        minWidth: '200px'
    },
    menuItem: {
        minHeight: '50px',
    },
    selectBox: {
        margin: '10px 20px 20px 10px',
        color: '#455A64',
    },
    selectRoot: {
        minHeight: '30px',
        height: '30px'
    },
    saveText: {
        color: theme.palette.primary.main,
        fontWeight: 600
    }
}));