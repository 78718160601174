/********************************************************
 * File: FilterSection.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-08-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
  Button, IconButton, Popover, Typography
} from '@material-ui/core';
import { AppTheme, itemColors } from '../../Theme';
import { Close } from '@material-ui/icons';

import 'react-date-range/dist/styles.css'; // date range style file
import 'react-date-range/dist/theme/default.css'; // date range theme css file
import { DateRange } from 'react-date-range';

export type TypeFilterConfig = {
  children?: React.ReactNode;
  name: string;
  selected: any[];
  setSelected?: React.Dispatch<React.SetStateAction<any[]>>;
  dateSelector?: boolean;
  chipColorGroup?: 'account' | 'goal' | 'product';
  hierarchySelector?: JSX.Element;
  readOnly?: boolean;
}

type TypeFilterSectionProps = {
  children?: React.ReactNode;
  title: string;
  filters: TypeFilterConfig[];
  gridSelector?: JSX.Element;
}

type TypeChipProps = {
  children?: React.ReactNode;
  item: any;
  selected: any;
  setSelected?: React.Dispatch<React.SetStateAction<any[]>>;
  chipColorGroup: 'account' | 'goal' | 'product'; // corresponds to section of the Theme file
  readOnly?: boolean;
}

// the pill shaped tag chips showing a scope and label
function Chip({
  item,
  selected,
  setSelected,
  chipColorGroup,
  readOnly
}: TypeChipProps) {
  const style = styles(AppTheme);
  return (
    <div className={style.chip} key={`chip-${item.scope}-${item.label}`}>
      <Typography
        color="secondary"
        className={style.chipScope}
        style={{backgroundColor: itemColors[chipColorGroup].scope}}
      >
        {item.scope ?? ''}
      </Typography>
      <div 
        className={style.chipLabel}
        style={{backgroundColor: itemColors[chipColorGroup].label}}
      >
        <Typography color="secondary">{item.label ?? ''}</Typography>
        {(typeof readOnly === 'undefined' || (typeof readOnly === 'boolean' && !readOnly)) ?
          <div 
            className={style.chipDelete}
            onClick={() => {
              item.value && setSelected && setSelected(selected.filter((s: any) => s.value !== item.value));
            }}
          >
            <Close fontSize='small'/>
          </div>
          :
          <div className={style.chipRightFiller} />
        }
      </div>
    </div>
  );
}

function DateRangeFilter({
  selected,
  setSelected,
  readOnly
}: TypeFilterConfig) {
  const style = styles(AppTheme);
  // popover anchor
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dtOptions = { day: 'numeric', month: 'long', year: 'numeric' };

  return (
    <div className={style.dateRangeContainer}>
      <Typography color="secondary">{'Date Range:'}</Typography>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)} disabled={readOnly}>
        <Typography color='secondary' variant='body1' className={style.dateTypography}>
          {
          (selected[0].startDate == null && selected[0].endDate == null) ? 
          'Any'
          :
          ((selected[0].startDate ? selected[0].startDate.toLocaleDateString('en-US', dtOptions) : '')
          + '  -  '
          + (selected[0].endDate ? selected[0].endDate.toLocaleDateString('en-US', dtOptions) : ''))
          }
        </Typography>
      </Button>
      {(typeof readOnly === 'undefined' || (typeof readOnly === 'boolean' && !readOnly)) &&
        <><IconButton onClick={() => setSelected && setSelected([{
          ...selected[0],
          startDate: null,
          endDate: null
        }])}>
          <Close fontSize='small'/>
        </IconButton>
        <Popover
          id='date-range-popover'
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          className={style.popover}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <DateRange
            editableDateInputs
            onChange={item => setSelected && setSelected([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={selected}
            startDatePlaceholder='Any'
            endDatePlaceholder='Any'
          />
        </Popover></>
      }
    </div>
  );
}


// a filter line (e.g. account attributes or excluded orgs)
function Filter({
  name,
  selected,
  setSelected,
  hierarchySelector,
  chipColorGroup,
  readOnly
}: TypeFilterConfig) {
  const style = styles(AppTheme);

  return (
    <div className={style.filter} key={`filter-${name}-`}>
      <Typography color="secondary" className={style.filterName}>{name + ':'}</Typography>
      {selected.map(item => <Chip
        key={`${name}-chip-${item.scope}-${item.label}`}
        item={item}
        selected={selected}
        setSelected={setSelected}
        chipColorGroup={chipColorGroup ?? 'account'}
        readOnly={readOnly}
      />)}
      {hierarchySelector && hierarchySelector}
    </div>
  );
}

// the section showing all the filters for an item type, e.g. account attributes and
//    orgs for account
export function FilterSection({
  title,
  filters,
  gridSelector
}: TypeFilterSectionProps) {
  const style = styles(AppTheme);

  return (
    <div className={style.root}>
      <div className={style.centered}>
        <Typography color="secondary" variant='h6'>{title}</Typography>

        <div className={style.filtersContainer}>
          {filters.map(filter => {
            if (filter.dateSelector) {
              return (
                <DateRangeFilter
                  key={`${title}-filter-${filter.name}`}
                  name={filter.name}
                  selected={filter.selected}
                  setSelected={filter.setSelected}
                  readOnly={filter.readOnly}
                />
              );
            } else {
              return (
                <Filter
                  key={`${title}-filter-${filter.name}`}
                  name={filter.name}
                  selected={filter.selected}
                  setSelected={filter.setSelected}
                  dateSelector={filter.dateSelector}
                  hierarchySelector={filter.hierarchySelector}
                  chipColorGroup={filter.chipColorGroup}
                  readOnly={filter.readOnly}
                />
              );
            }
          })}
          {gridSelector && gridSelector}
        </div>
      </div>
    </div>
  );
}

const chipHeight = '24px';
const styles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginBottom: '20px',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    alignItems: 'flex-start',
    padding: '0px 5px 10px 5px',
    margin: '5px 0px 5px 0px',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
    marginBottom: '20px',
    width: '100%',
  },
  filter: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '6px 0px 6px 0px',
    minHeight: '40px',
  },
  filterName: {
    marginRight: 10,
  },
  chip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: chipHeight,
    borderRadius: '16px',
    margin: '6px 3px',
    textAlign: 'center',
  },
  chipScope: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: chipHeight,
    borderTopLeftRadius: '16px',
    borderBottomLeftRadius: '16px',
    padding: '3px 10px 3px 12px',
    textAlign: 'center',
    fontFamily: ["Roboto", "Arial", "Helvetica", "sans-serif", "system-ui"].join(','),
    color: 'white'
  },
  chipLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: chipHeight,
    borderTopRightRadius: '16px',
    borderBottomRightRadius: '16px',
    padding: '3px 8px 3px 12px',
    textAlign: 'center',
  },
  chipDelete: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    cursor: 'pointer'
  },
  chipRightFiller: {
    width: '8px'
  },
  popover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  popoverPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10
  },
  dateRangeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '5px 0px 15px 0px',
    textAlign: 'center'
  },
  dateTypography: {
    textTransform: 'none',
    textAlign: 'center'
  }
}));