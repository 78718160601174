/********************************************************
 * File: GoalFiltersSection.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-08-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useState, useEffect, useRef } from 'react';
import { map as loMap, get as loGet, last as loLast, assign as loAssign } from 'lodash';
import { TypeTracker } from '../../typings/tracker.types';
import { FilterSection, TypeFilterConfig } from '../core/FilterSection';
import { GridColDef } from '@material-ui/data-grid';
import {
  getFirestore, collection, onSnapshot, DocumentData, query, where, getDocs, DocumentSnapshot
} from 'firebase/firestore';
import { SelectorGrid } from '../core/ItemSelectorGrid';
import HierarchicalSelectorMenu from '../../components/core/HierarchicalSelectorMenu';

// Goal selector grid columns
const maxWidth = 1000;
const goalCols: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: maxWidth*.18 },
  { field: 'goalType', headerName: 'Goal Type', width: maxWidth*.18, sortable: false },
  { field: 'org', headerName: 'Org', width: maxWidth*.18, sortable: false },
  { field: 'goalPeriod.startDate', headerName: 'Start', width: maxWidth*.18, type: 'date' },
  { field: 'goalPeriod.endDate', headerName: 'End', width: maxWidth*.18, type: 'date' },
];

const parseGoalData = (t: DocumentSnapshot<DocumentData>) => {
  let sd = t.get('goalPeriod.startDate');
  if (typeof sd === 'string') sd = new Date(sd);
  else if (typeof sd === 'object') sd = sd.toDate();
  let ed = t.get('goalPeriod.endDate');
  if (typeof ed === 'string') ed = new Date(ed);
  else if (typeof ed === 'object') ed = ed.toDate();
  return ({
    id: t.id ?? '',
    shortId: t.id ? t.id.slice(0,5) : '',
    name: t.get('name') ?? '',
    org: t.get('org') ?? '',
    goalType: t.get('goalTypeDisplay') ?? t.get('goalType') ?? '',
    isExplicit: t.get('isExplicit') ?? false,
    'goalPeriod.startDate': sd ?? '',
    'goalPeriod.endDate': ed ?? '',
    included: false, // default to false, updated on page load
    grayRow: true, // default to true, updated on page load
  });
}

type TypeGoalFiltersSectionProps = {
  tracker: TypeTracker;
  isStateDirty: boolean;
  attributes: any[];
  excludeAttributes: any[];
  setExcludeAttributes: React.Dispatch<React.SetStateAction<any[]>>;
  setAttributes: React.Dispatch<React.SetStateAction<any[]>>;
  goalPeriod: any[];
  setGoalPeriod: React.Dispatch<React.SetStateAction<any[]>>;
  userOrg: string;
  setIsStateDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

function GoalFiltersSection({
  tracker,
  isStateDirty,
  attributes,
  setAttributes,
  excludeAttributes,
  setExcludeAttributes,
  goalPeriod,
  setGoalPeriod,
  userOrg,
  setIsStateDirty
}: TypeGoalFiltersSectionProps) {
  const db = getFirestore();

  //////////////////////
  // HIERARCHY SELECTORS
  //////////////////////

  /////////
  // attributes selector
  const [allAttributes, setAllAttributes] = useState<any[]>([]);
  const loadingAttributes = useRef(false);
  const delegate = async (selectedPath: any[], selectedOptions: any[], close:any) => {
    loadingAttributes.current = true;
    if (selectedPath.length === 0) {
      // Nothing has been drilled, show initial list
      loadingAttributes.current = false;
      return loMap(allAttributes, (entry, index) => ({
        index: index,
        value: entry.id,
        display: entry.display,
        key: entry.key,
        chevron: true
      }));
    }

    // One item has been drilled, so show the enums for it.
    const selectedIndex = loGet(loLast(selectedPath), 'index')
    loadingAttributes.current = false;
    return loMap(loGet(allAttributes, `${selectedIndex}.enums`) ?? [], entry => ({
      value: entry.value,
      display: entry.display,
      chevron: false
    }));
  }

  // load goal attributes on attach
  useEffect(() => {
    loadingAttributes.current = true;
    return onSnapshot(
      query(collection(db, "Attribute"), where("collection", "==", "Goal")),
      snap => {
        loadingAttributes.current = false;
        setAllAttributes(loMap(snap.docs, doc => loAssign(doc.data(), { id: doc.id })))
      }
    );
  }, []);

  const transformSelection = (selectedPath: any[], selectedOption: any) => ({
    key: selectedPath[0]?.key ?? '',
    value: selectedOption.value ?? '',
    //display: selectedOption.display ?? selectedOption.value,
    scope: selectedPath[0]?.display ?? '',
    label: selectedOption.display ?? selectedOption.value ?? '',
  })

  const goalAttributeSelector = (
    <HierarchicalSelectorMenu
      delegate={delegate}
      selected={attributes}
      setSelected={setAttributes}
      transformSelection={transformSelection}
      loading={loadingAttributes}
      isMultiSelect
      leafOnly
    />
  );

  /////////
  // exclude attributes selector
  // reuse include delegate, loaded data

  const excludeGoalAttributeSelector = (
    <HierarchicalSelectorMenu
      delegate={delegate}
      selected={excludeAttributes}
      setSelected={setExcludeAttributes}
      transformSelection={transformSelection}
      loading={loadingAttributes}
      isMultiSelect
      leafOnly
    />
  );

  ////////////////
  // FILTER CONGIG
  ////////////////

  const goalFilters: TypeFilterConfig[] = [
    {
      name: 'Goal Attributes',
      selected: attributes,
      setSelected: setAttributes,
      hierarchySelector: goalAttributeSelector,
      chipColorGroup: 'goal'
    },
    {
      name: 'Exclude Goal Attributes',
      selected: excludeAttributes,
      setSelected: setExcludeAttributes,
      hierarchySelector: excludeGoalAttributeSelector,
      chipColorGroup: 'goal'
    },
    {
      name: 'Goal Period',
      selected: goalPeriod,
      setSelected: setGoalPeriod,
      dateSelector: true
    },
  ];

  return (
    <FilterSection
      title='Goal Filters'
      filters={goalFilters}
      gridSelector={(
        <SelectorGrid
          trackerId={tracker.id ?? ''}
          itemType='Goal'
          cols={goalCols}
          parseItemData={parseGoalData}
          staleDataMessage='Save to recalculate included goals'
          isStateDirty={isStateDirty}
          userOrg={userOrg}
          orderBy={'org'}
          setIsStateDirty={setIsStateDirty}
        />
      )}
    />
  )
}

export default GoalFiltersSection;