/********************************************************
 * File: TrackerList.tsx
 * Project: @liquid-mc/ui
 * File Created: 08-31-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useState, useRef, useEffect } from 'react';
import { TypeTracker } from '../../typings/tracker.types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppTheme } from '../../Theme';
import { 
  Button, Divider, Grid,
  Paper, Typography, ButtonGroup, Icon, InputBase, Fab, Switch, List
} from '@material-ui/core';
import {Add, Edit, KeyboardArrowDown, KeyboardArrowUp, Lock, Search } from '@material-ui/icons';
import { Paginate } from '../core/Paginate';
import { Unsubscribe, where, DocumentSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { paginatedFetchData } from '../../api/firestoreFetch';
import { debounce } from 'lodash';

type TypeOrderBy = 'LAST_MODIFIED' | 'NAME' | 'CREATED_BY';
const trackerOrderFieldMap = {
  LAST_MODIFIED: 'updatedAt',
  NAME: 'name',
  CREATED_BY: 'createdBy'
}

type TypeRenderRowProps = {
  children?: React.ReactNode;
  tracker: TypeTracker;
  idx: number;
  onRowClick?: (tracker: TypeTracker) => void;
  onEditClick?: (tracker: TypeTracker) => void;
}

// renders a tracker list item
function RenderRow({ tracker, idx, onRowClick, onEditClick, ...props }: TypeRenderRowProps) {
  const style = styles(AppTheme);
  return (
    <div>
      <div className={style.rowContainer}>
        {/* main row button going to metrics / reports page */}
        <Button
          onClick={() => onRowClick && onRowClick(tracker)}
          key={`${tracker.id}`}
          fullWidth
          className={style.rowButton}
        >
          {/* start components */}
          <div className={style.rowStart}>
            {/* lock icon if private */}
            <div className={style.lockContainer}>
              {tracker.private &&
                <Icon className={style.lockIcon}>
                  <Lock />
                </Icon>
              }
            </div>
            {/* Name and description column */}
            <Grid container direction='column' className={style.leftMargin}>
              {/* Name */}
              <Typography 
                color="secondary"
                variant="h6"
                className={style.regText}
              >
                {tracker.name ?? tracker.id}
              </Typography>
              {/* Description */}
              <div className={clsx(style.descriptionContainer, style.regText)} >
                  {tracker.description ?? ''}
              </div>
            </Grid>
          </div>

          {/* end components */}
          <div className={style.rowEnd}>
            {/* created by */}
            <Typography
              color="secondary"
              variant='subtitle2'
              className={clsx(style.regText, style.rowEndText)}
              noWrap
            >
              {tracker.createdBy && tracker.createdBy}
            </Typography>
            {/* updated at */}
            <Typography 
              color="secondary"
              variant='subtitle2'
              className={clsx(style.regText, style.rowEndText)}
              noWrap
            >
              {tracker.updatedAt && tracker.updatedAt.toDateString()}
            </Typography>
          </div>
        </Button>

        {/* edit button to go to edit details page */}
        <Button 
          onClick={() => onEditClick && onEditClick(tracker)}
          key={`${tracker.id}-edit`}
          className={style.flexCenter}
        >
          <Grid item className={style.editContainer}>
            <Icon className={style.lockIcon}>
              <Edit />
            </Icon>
          </Grid>
        </Button>
      </div>
      <Divider light />
    </div>
  );
}

type TypeTrackerHeaderProps = {
  children?: React.ReactNode;
  orderByVal: TypeOrderBy;
  setOrderByVal: React.Dispatch<React.SetStateAction<TypeOrderBy>>;
  ascending: boolean;
  setAscending: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchVal: React.Dispatch<React.SetStateAction<string>>;
  setShowPrivate: React.Dispatch<React.SetStateAction<boolean>>;
  showPrivate: boolean;
}

function TrackerHeader({
  orderByVal,
  setOrderByVal,
  ascending,
  setAscending,
  setSearchVal,
  setShowPrivate,
  showPrivate
}: TypeTrackerHeaderProps) {
  const style = styles(AppTheme);

  // debounced func only fires after specified ms
  const setSearchValDebounced = debounce(setSearchVal, 300);
  // the search value shown in the search bar (not debounced)
  const [shownSearch, setShownSearch] = useState('');

  const auth = getAuth();
  const user = auth.currentUser?.email;

  function orderButton(id: TypeOrderBy, disabled?: boolean) {
    const selected = orderByVal === id;
    const arrow = ascending ? <KeyboardArrowUp/> : <KeyboardArrowDown/>;
    return (
      <Button 
        variant={orderByVal === id ? 'contained' : 'outlined'} 
        color='primary'
        onClick={() => {
          if (selected) setAscending(!ascending);
          else setOrderByVal(id);
        }}
      >
        {id.replaceAll('_', ' ')}
        {selected && arrow}
      </Button>
    );
  }

  return (
    <div className={style.headerContainer}>
      <Grid container direction='row' wrap='nowrap' justifyContent='space-between' className={style.headerGridContainer}>
        
        {/* container for left side of header */}
        <Grid container item direction='column' wrap='nowrap' justifyContent='flex-end' className={style.marginBottom}>
          {/* order by */}
          <ButtonGroup aria-label="order by" disableElevation size='small'>
            {orderButton('NAME')}
            {!showPrivate && orderButton('CREATED_BY')}
            {orderButton('LAST_MODIFIED')}
          </ButtonGroup>
        </Grid>

        <Grid container item wrap='nowrap' alignItems='flex-end' className={style.marginBottom}>
          <div className={style.headerCenter}>
            <Switch 
              color='primary'
              disabled={user == null}
              onChange={e => {
                if (e.target.checked) {
                  setShowPrivate(true);
                } else {
                  setShowPrivate(false);
                }
              }}
            />
            <Typography color='secondary'>
              My Trackers
            </Typography>
          </div>
        </Grid>

        {/* search */}
        <Grid container item direction='row' alignItems='flex-end' justifyContent='flex-end' className={style.marginBottom}>
          <div className={style.search}>
            <div className={style.searchIcon}>
              <Search />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: style.inputRoot,
                input: style.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value={shownSearch}
              onChange={(e) => {
                if (typeof e.target.value === 'string' && e.target.value.length <= 3) {
                  setShownSearch(e.target.value);
                  setSearchValDebounced(e.target.value);
                }
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}




type TypeTrackerListProps = {
  children?: React.ReactNode;
  onRowClick: (tracker: TypeTracker) => void;
  onEditClick: (tracker: TypeTracker) => void;
  onAddClick: () => void;
}

// renders the virtualized tracker list
export function TrackerList({ onRowClick, onEditClick, onAddClick}: TypeTrackerListProps) {
  const style = styles(AppTheme);

  // State for paginated data fetching
  const [trackers, setTrackers] = useState<TypeTracker[]>([]);
  const [orderByVal, setOrderByVal] = useState<TypeOrderBy>('LAST_MODIFIED');
  const [ascending, setAscending] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [showMine, setShowMine] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  // need both offsets to compare new one with old to figure out pagination
  // see fetchData()
  const [offset, setOffset] = useState(0); // offset updated by the pagination component
  // paginated list state
  const [totalTrackers, setTotalTrackers] = useState(-1);
  const currOffset = useRef(0); // offset currently shown in the list
  const firstDoc = useRef<TypeTracker>();
  const lastDoc = useRef<TypeTracker>();
  const loading = useRef(false);
  const unsubscribes = useRef<Unsubscribe[]>([]);
  const parseTrackerData = (t: DocumentSnapshot) => {
    // get date and convert to JS Date
    let ua = t.get('updatedAt') ?? null;
    if (ua != null) ua = ua.toDate();
  
    // get other fields in the return
    return ({
      id: t.id,
      name: t.get('name'),
      description: t.get('description'),
      updatedAt: ua,
      createdBy: t.get('createdBy'),
      private: t.get('private') ?? false,
      goalPresets: t.get('goalPresets'),
      trackerTemplateIds: t.get('trackerTemplateIds') ?? []
    } as TypeTracker);
  }

  const auth = getAuth();
  const user = auth.currentUser?.email;
  let userCountField = 'nonPrivateCount';
  // count field is lowercase email without any '.'s
  if (typeof user === 'string') userCountField = user.toLowerCase().split('.').join('');

  const constraints = [where('private', '==', false), where('status', '==', 'active')];
  const privateConstraints = [where('createdBy', '==', user), where('status', '==', 'active')];

  ////////////
  // HOOKS
  ////////////
  
  // fetch trackers asynchronously on load and when order or search values change
  useEffect(() => {
    paginatedFetchData({
      collectionName: 'Tracker',
      countDoc: 'Aggregate/tracker',
      parseCount: showMine ? ((d) => d.get(userCountField) ?? 0) : ((d) => d.get('nonPrivateCount') ?? 0),
      setItems: setTrackers,
      loading: loading,
      unsubscribes: unsubscribes,
      pageSize: pageSize,
      parseItem: parseTrackerData,
      offset: offset,
      orderByVal: trackerOrderFieldMap[orderByVal] ?? '',

      ascending: ascending,
      searchVal: searchVal,
      constraints: showMine ? privateConstraints : constraints,
      totalItems: totalTrackers,
      setTotalItems: setTotalTrackers,
      currOffset: currOffset,
      firstDoc: firstDoc,
      lastDoc: lastDoc,
    })

    // unsubscribe listener on cleanup
    return function cleanUp() {
      unsubscribes.current.forEach(u => u());
      unsubscribes.current = [];
    }
  }, [ascending, searchVal, offset, pageSize]);

  // hook for specifically orderBy & constraints(reset page / offset when changed)
  useEffect(() => {
    // if switching to show private trackers and currently ordering by created by,
    //    change to name (can't order by created by because it is already filtered on)
    let ob = trackerOrderFieldMap[orderByVal] ?? '';
    if (showMine && orderByVal === 'CREATED_BY') {
      setOrderByVal('NAME')
      ob = trackerOrderFieldMap['NAME'] ?? '';
    }
    // reset offset to zero
    setOffset(0);
    paginatedFetchData({
      collectionName: 'Tracker',
      countDoc: 'Aggregate/tracker',
      parseCount: showMine ? ((d) => d.get(userCountField) ?? 0) : ((d) => d.get('nonPrivateCount') ?? 0),
      setItems: setTrackers,
      loading: loading,
      unsubscribes: unsubscribes,
      pageSize: pageSize,
      parseItem: parseTrackerData,
      offset: offset,
      orderByVal: ob,
      ascending: ascending,
      searchVal: searchVal,
      constraints: showMine ? privateConstraints : constraints,
      totalItems: totalTrackers,
      setTotalItems: setTotalTrackers,
      currOffset: currOffset,
      firstDoc: firstDoc,
      lastDoc: lastDoc,
    })

    // unsubscribe listener on cleanup
    return function cleanUp() {
      unsubscribes.current.forEach(u => u());
      unsubscribes.current = [];
    }
  }, [orderByVal, showMine]);

  return (
    <div className={style.root}>
      <div className={style.listContainer}>
        <TrackerHeader 
          orderByVal={orderByVal}
          setOrderByVal={setOrderByVal}
          ascending={ascending}
          setAscending={setAscending}
          setSearchVal={setSearchVal}
          setShowPrivate={setShowMine}
          showPrivate={showMine}
        />
        <Fab color='primary' size='medium' onClick={onAddClick} className={style.fab}>
          <Add/>
        </Fab>
          <Paper
            className={style.listPaper}
            variant={'outlined'}
          >
            <List className={style.list}>
              {trackers.map((t, idx) => <RenderRow
                key={JSON.stringify(t)}
                tracker={t}
                idx={idx}
                onRowClick={onRowClick}
                onEditClick={onEditClick}
              />)}
            </List>
          </Paper>
        <Paginate
          offset={offset}
          setOffset={setOffset}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalItems={totalTrackers}
        />
      </div>
    </div>
  );
}

const rowHeight = 80;
const styles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  listPaper: {
    width: '100%',
    marginBottom: '5px',
  },
  list: {
    overflow: 'hidden',
    width: '100%',
    paddingBottom: 0,
    paddingTop: 0
  },

  marginBottom: {
    marginBottom: 7
  },

  fab: {
    position: 'fixed',
    right: '13%',
    bottom: '6%',
    zIndex: 3,
    color: 'primary',
    cursor: 'pointer'
  },

  pageSizeFormControl: {
    minWidth: 75,
  },
  pageSizeContainer: {
    display: 'flex',
    width: '46%',
    marginRight: 4,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-end',
    height: 80,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    paddingLeft: 0,
    paddingRight: 2,
    paddingBottom: 0
  },
  headerGridContainer: {
    alignContent: 'center',
  },
  headerCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  listContainer: {
    width: '80%',
    height: '100%',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: rowHeight,
  },
  rowButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rowStart: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 60,
  },
  descriptionContainer: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'grey',
    lineHeight: '1.2em',
    marginTop: 5
  },
  rowEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowEndText: {
    marginLeft: 10,
    marginRight: 10,
  },
  regText: {
    textTransform: 'none',
    textAlign: 'start',
  },
  lockContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    marginLeft: 20,
  },
  lockIcon: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    color: '#616161'
  },
  editContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  // header search bar
  search: {
    display: 'flex',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#e3e3e3',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    transition: 'background-color 0.2s',
    marginTop: theme.spacing(1),
    marginLeft: 0,
    width: '40%',
    height: 40,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    color: '#455A64',
    paddingLeft: theme.spacing(1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    color: '#455A64',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  leftMargin: {
    marginLeft: 25,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100
  }
}));