import { 
  getFirestore, collection, onSnapshot, Unsubscribe,
  doc, getDoc, DocumentSnapshot, QueryConstraint,
  query, where, limit, orderBy, limitToLast, startAfter, endBefore
} from 'firebase/firestore';
import { first as loFirst, last as loLast, get as loGet } from 'lodash';

type TypeFetchParams = {
  collectionName: string;
  countDoc: string;
  parseCount?: (d: DocumentSnapshot) => number;
  setItems: React.Dispatch<React.SetStateAction<any[]>>;
  loading: React.MutableRefObject<boolean>;
  unsubscribe: React.MutableRefObject<Unsubscribe>;
  parseItem: (item: DocumentSnapshot) => any;

  // query params
  orderByVal?: string;
  ascending?: boolean;
  searchVal?: string;
}

export async function fetchData({
  collectionName,
  setItems,
  loading,
  unsubscribe,
  parseItem,
  orderByVal,
  ascending,
  searchVal,
}: TypeFetchParams) {
  const db = getFirestore();
  // unsub from old FireStore listeners if any
  unsubscribe.current();
  let q = query(collection(db, collectionName));

  if (typeof orderByVal === 'string' && orderByVal.length > 0) {
    q = query(q, orderBy(orderByVal, ascending ? 'asc' : 'desc'));
  }

  // if there is a search value, add it to the query
  if (typeof searchVal === 'string' && searchVal.length > 0) {
    // only the first 3 tokens because trigram search
    const search = searchVal.slice(0,3).toLowerCase();
    q = query(q, where('tokens', 'array-contains', search));
  }

  unsubscribe.current = onSnapshot(
    // query
    q,
    // success callback
    (items) => {
      loading.current = false;
      setItems(items.docs.map(t => parseItem(t)));
    },
    // error callback
    (err) => {
      console.error(err);
      loading.current = false;
    }
  );
}

// all the params needed for paginated data fetch
type TypePaginatedFetchParams = {
  collectionName: string;
  countDoc: string;
  parseCount?: (d: DocumentSnapshot) => number;
  setItems: React.Dispatch<React.SetStateAction<any[]>>;
  loading: React.MutableRefObject<boolean>;
  unsubscribes: React.MutableRefObject<Unsubscribe[]>;
  parseItem: (item: DocumentSnapshot) => any;
  hasNextPageMode?: boolean;

  // query params
  orderByVal: string;
  ascending: boolean;
  searchVal?: string;
  constraints?: QueryConstraint[];

  // pagination
  pageSize: number;
  offset: number;
  totalItems: number;
  setTotalItems: React.Dispatch<React.SetStateAction<number>>;
  currOffset: React.MutableRefObject<number>;
  firstDoc: React.MutableRefObject<any | undefined>;
  lastDoc: React.MutableRefObject<any | undefined>;
}

export const paginatedFetchData = async ({
  collectionName,
  setItems,
  loading,
  unsubscribes,
  pageSize,
  parseItem,
  hasNextPageMode=false,
  offset,
  orderByVal,
  ascending,
  searchVal,
  constraints,
  totalItems,
  setTotalItems,
  currOffset,
  firstDoc,
  lastDoc,
  countDoc,
  parseCount
}: TypePaginatedFetchParams) => {
  const db = getFirestore();
  // unsub from old FireStore listeners if any
  unsubscribes.current.forEach(u => u());
  unsubscribes.current = [];

  loading.current = true;
  
  // get the total count if not just checking for items on the next page
  if (!hasNextPageMode) {
    unsubscribes.current.push(onSnapshot(
      doc(db, countDoc),
      (cd) => {
        let total: null | number = null;
        if (parseCount && typeof parseCount === 'function') {
          total = parseCount(cd);
        } else {
          total = cd.get('Count') ?? cd.get('count') ?? 0;
        }
        if (typeof total !== 'number') {
          total = 0;
        }
        setTotalItems(total);
      },
      (err) => {
    console.log(err);
      }
    ));
  }

  const firstDocOrderVal = loGet(firstDoc.current, orderByVal, '');
  const lastDocOrderVal = loGet(lastDoc.current, orderByVal, '');

  let q = query(
    collection(db, collectionName),
    orderBy(orderByVal, ascending ? 'asc' : 'desc'),
  );

  // if we are not sorting by the id, we could be sorting by a field
  //   that isn't unique. because we use this order for pagination,
  //   we need to order by the id as well.
  if (orderByVal !== 'id') {
    q = query(q, orderBy('id', 'asc'));
  }

  if (Array.isArray(constraints) && constraints.length > 0) {
    q = query(q, ...constraints);
  }

  // if there is a search value, add it to the query
  if (typeof searchVal === 'string' && searchVal.length > 0) {
    // only the first 3 tokens because trigram search
    const search = searchVal.slice(0,3).toLowerCase();
    q = query(q, where('tokens', 'array-contains', search));
  }

  // pagination!
  // paginate by starting after the last doc on the previous page if
  //   page forward is clicked, before first doc if backwards, etc.
  if (offset == 0) { // first page
    q = query(q, limit(hasNextPageMode ? (pageSize + 1) : pageSize));
  } else if (offset > currOffset.current) { // next page or last page
    if ((totalItems - (currOffset.current + pageSize)) < pageSize) { // last page
      // mod to get # on final page
      let pgSz = totalItems % pageSize;
      q = query(q, limitToLast(pgSz));
    } else { // next page
      const startAfterClause = orderByVal === 'id' ? startAfter(lastDocOrderVal) : startAfter(lastDocOrderVal, lastDoc.current.id);
      q = query(q, limit(hasNextPageMode ? (pageSize + 1) : pageSize), startAfterClause);
    }
  } else { // previous page (first page already handled above)
    const endBeforeClause = orderByVal === 'id' ? endBefore(firstDocOrderVal) : endBefore(firstDocOrderVal, firstDoc.current.id);
    q = query(q, limitToLast(hasNextPageMode ? (pageSize + 1) : pageSize), endBeforeClause);
  };

  unsubscribes.current.push(onSnapshot(
    // query
    q,
    // success callback
    (items) => {
      const tempItems: any[] = [];
      items.docs.forEach(t => tempItems.push(parseItem(t)));
      loading.current = false;
      firstDoc.current = loFirst(tempItems);
      lastDoc.current = loLast(tempItems);
      setItems(tempItems);
    },
    // error callback
   (err) => {
      console.error(err);
      loading.current = false;
    }
  ));
  currOffset.current = offset;
};

export async function fetchTrackerTemplate(templateId: string) {
  const db = getFirestore();
  const template = await getDoc(doc(db, `DataTableTemplate/${templateId}`));
  if (!template.exists()) {
    console.error(`Error: Data Table Template with ID '${templateId}' does not exist!`);
    return;
  }
  return template;
}
