/********************************************************
 * File: AppBar.tsx
 * Project: @liquid-perform/mc
 * File Created: 08-19-2021
 * Author: Jenny Mistry (jenny@liquidanalytics.com)
 *
 * Copyright © 2021 Liquid Analytics
 *********************************************************/

import React, { useEffect, useState } from "react";
// firebase
import Cookie, {parse, serialize} from 'cookie';
import { getAuth, signOut } from "@firebase/auth";
import { createStyles, makeStyles } from "@material-ui/styles";
import { AppBar, Button, Menu, Theme, Toolbar, Typography, useMediaQuery } from "@material-ui/core";
import { AppBarMenuActions, ContextActionId } from "../../common/constants";
import { AppStyledMenuItem } from "../core/Styled.Menu";
import { TypeAppBarContextualActions } from "../../typings/component.types";
import TooltipWrapper from "../core/Tooltip.Wrapper";
import { AppTheme } from "../../Theme";
import { AccountCircle } from "@material-ui/icons";
import { history } from '../../index';
import LiquidDropWhite from "../../assets/Images/LiquidDropWhite.png";

/** Constants */
const drawerWidth = 240;
const compact = 72;
const barDesktopHeight = 64;
 
/**
* AppBar Component
* @param props
*/
const AppBarComp = (props: any) => {
  const auth = getAuth();

  /////////////////////
  // STATE and HOOKs
  /////////////////////
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);

   /////////////////////
  // CONSTANTS
  /////////////////////
  const style = appBarStyles(AppTheme);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const xsMatch = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const {barTitle, ...appbarprops} = props;

  /////////////////////
  // EVENT HANDLERS
  /////////////////////
  const menuCloseHandler = (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => {
    setAnchorEl(null);
  };

  const mobileMenuCloseHandler = (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => {
    setMobileMoreAnchorEl(null);
  };


  const menuItemSelectHandler = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    action: TypeAppBarContextualActions,
    id?: string
  ) => {
    switch (action.id) {
      case ContextActionId.LOGOUT:
        (async () => {
          await signOut(auth);
          document.cookie = "access_token=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          location.reload();
        })();
        break;
      
      default:
        break;
    }

    menuCloseHandler();
    mobileMenuItemCloseHandler(event, action);
  };

  const mobileMenuItemCloseHandler = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, action: TypeAppBarContextualActions) => {
    mobileMenuCloseHandler();
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  ///////////////////////
  // AppBar Profile Actions
  //////////////////////
  const AppBarProfileActions = (
    AppBarMenuActions && AppBarMenuActions.map((action, i) => {
      switch (action.id) {
      default:
        return (
          <AppStyledMenuItem
            key={`profile-${action.id}-${i}`}
            onClick={(e: any) => menuItemSelectHandler(e, action)}
            label={action.label}
            Icon={action.Icon}
          />
        );
      }
    })
  );
    
  ///////////////////////
  // Overflow Actions
  //////////////////////
  /** common actions */
  const defaultOverflowActions = (
    AppBarMenuActions.map((action, i) => {
      const Icon = action.Icon;
      return (
        <AppStyledMenuItem
          key={`common-${action.id}-${i}`}
          label={action.label}
          Icon={Icon}
          onClick={(e: any) => menuItemSelectHandler(e, action)}
        />
      )
    })
  );

  ///////////////////////
  // Profile Menu Element
  ///////////////////////
  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      transformOrigin={{ vertical: 'bottom', horizontal: "center" }}
      id="appbar-profile-element"
      keepMounted
      open={isMenuOpen}
      onClose={menuCloseHandler}
      classes={{ list: style.menuList }}
    >
      {AppBarProfileActions}
    </Menu>
  );

   ///////////////////////
  // Overflow Element
  //////////////////////
  const menuId = "appbar-overflow-element";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}

      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={menuCloseHandler}
    >
      {/* {overflowActions}*/}
      {defaultOverflowActions} 
      <hr />
     
    </Menu>
  );

   ///////////////////////////
  // Mobile Overflow Element
  //////////////////////////
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="appbar-overflow-element-mobile"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={mobileMenuCloseHandler}
    >
      {/* {overflowActions} */}
      {defaultOverflowActions}
      <hr />
      
    </Menu>
  );

  ///////////////////////
  // FC Element
  //////////////////////
  return (
    <div className={style.root}>
      <AppBar color='primary' position="fixed" className={style.appBar} >
        <Toolbar className={style.toolbar}>
          <div className={style.leftBar}>
            {/* hamburger menu icon */}
            {/* <Button className={style.hamburger}>
              <MenuIcon className={style.white} fontSize='large'/>
            </Button> */}
            <Button className={style.homeButton} onClick={() => {
              history.push({ pathname: '/TrackerList' });
            }}>
              <img src={LiquidDropWhite} alt="Liquid Analytics Logo" className={style.liquidDrop} />
              <Typography className={style.title} variant="h4" noWrap>
                {barTitle}
              </Typography>
            </Button>
          </div>
          {/** appbar actions */}
          <TooltipWrapper key={'profile-menu-button'} title={'User Menu'}>
            <Button onClick={(e) => handleProfileMenuOpen(e)}>
              <AccountCircle className={style.white} fontSize='large'/>
              <Typography variant='body1' className={style.userText}>
                {auth.currentUser?.email ?? 'no email'}
              </Typography>
            </Button>
          </TooltipWrapper>
        </Toolbar>
      </AppBar>
      {renderProfileMenu}
      {xsMatch && renderMobileMenu}
      {xsMatch && renderMenu}
    </div>
  );
};
 
/** MUI STYLES */
const appBarStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
  },
  mainColor: {
    color: '#455A64'
  },
  white: {
    color: 'white'
  },
  userText: {
    textTransform: 'none',
    marginLeft: '10px',
    color: 'white' // '#455A64'
  },
  appBar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: `100%`,
    height: '65px',
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: "#000",
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  homeButton: {
    padding: '6px 15px 6px 15px',
    marginLeft: '10px',
    textTransform: 'none'
  },
  title: {
    color: 'white',
    fontSize: '28px'
  },
  menuList: {
    padding: 0,
    minWidth: '200px'
  },
  liquidDrop: {
    minWidth: '18px',
    width: '18px',
    marginRight: '15px'
  }
}));
 
export default AppBarComp;