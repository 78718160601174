import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from 'react-router';
import { createBrowserHistory } from "history";
import { initializeApp, getApps } from "firebase/app";

import { appConfig } from './firebase.config';

import { AppTheme } from "./Theme";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

export const history = createBrowserHistory();

//Create App Theme
const theme = createTheme(AppTheme);

// Initialize the app using firebase      
if (getApps.length === 0) {
    console.log('initializing FB');
    initializeApp(appConfig);
}

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Router history={history}>
            <App />
        </Router>      
    </ThemeProvider>,
    document.getElementById('root')
);