/********************************************************
 * File: ItemSelectorGrid.tsx
 * Project: @liquid-mc/ui
 * File Created: 09-03-2021
 * Author: Fisher Moritzburke
 * fisher.moritzburke@liquidanalytics.com
 * Copyright © 2021 Liquid Analytics
*********************************************************/

import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { debounce } from 'lodash';
import {
  DataGrid, GridCellParams, GridColDef, GridSelectionModel, GridSortModel, GridRowId, GridRowParams
} from '@material-ui/data-grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import {
  Accordion, AccordionSummary, AccordionDetails, Button, Typography, ButtonGroup,
  InputBase, Tooltip, Checkbox
} from '@material-ui/core';
import { ArrowBack, ArrowBackIos, ArrowForward, ArrowForwardIos, Search } from '@material-ui/icons';
import { paginatedFetchData } from '../../api/firestoreFetch';
import {
  Unsubscribe, getFirestore, getDoc, doc, DocumentSnapshot, DocumentData, deleteDoc,
  setDoc, increment, updateDoc
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { AppTheme } from '../../Theme';

type TypeSelectorGridProps = {
  trackerId: string;
  itemType: 'Account' | 'Product' | 'Goal';
  parseItemData: (d: DocumentSnapshot<DocumentData>) => any;
  cols: GridColDef[];
  staleDataMessage?: string;
  isStateDirty?: boolean;
  setIsStateDirty: React.Dispatch<React.SetStateAction<boolean>>;
  userOrg?: string;
  orderBy?: string;
}

// render with tooltip showing full value
const renderCell = (params: GridCellParams, width: number) => {
  let value = params.value;
  if (value instanceof Date) value = value.toDateString();
  else if (typeof value !== 'string') value = '';
  return (
    <Tooltip title={value}>
      <Typography variant='body2' style={{
        width: width,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>
        {value}
      </Typography>
    </Tooltip>
  );
}

export function SelectorGrid({
  trackerId,
  itemType,
  cols,
  parseItemData,
  staleDataMessage,
  isStateDirty,
  setIsStateDirty,
  userOrg,
  orderBy
}: TypeSelectorGridProps) {
  const style = styles(AppTheme);
  const db = getFirestore();
  const auth = getAuth();

  const includeColPath = `Tracker/${trackerId}/Items/summary/${itemType}Include`;

  const indicatorColWidth = 90;
  const includedIndicatorCol: GridColDef = {
    field: 'included',
    headerName: 'Included',
    width: indicatorColWidth,
    type: 'boolean',
    sortable: false,
  };
  cols = cols.map(c => {
    const width = c.width ? (c.width - (indicatorColWidth / cols.length)) : indicatorColWidth
    return {
      ...c,
      width: width,
      renderCell: (params) => renderCell(params, width)
    }
  });

  const isRowSelectable = (params: GridCellParams) => {
    if (included) return true;
    return !params.row.grayRow && !params.row.included;
  }
  const isRowChecked = (rowId: string) => {
    if (included) {
      return selectedIncludes.map(item => item.id).includes(rowId);
    } else {
      return selectedItems.map(item => item.id).includes(rowId);
    }
  }
  const handleRowCheck = (row: any) => {
    if (included) {
      const idx = selectedIncludes.map(item => item.id).indexOf(row.id);
      if (idx === -1) setSelectedIncludes(selectedIncludes.concat([row]));
      else setSelectedIncludes(selectedIncludes.filter(item => item.id !== row.id));
    } else {
      const idx = selectedItems.map(item => item.id).indexOf(row.id);
      if (idx === -1) setSelectedItems(selectedItems.concat([row]));
      else setSelectedItems(selectedItems.filter(item => item.id !== row.id));
    }
  }
  const renderCheckbox = (params: GridCellParams) => (
    <Checkbox
      color='primary'
      value={params.row.id}
      disabled={!isRowSelectable(params)}
      onChange={() => handleRowCheck(params.row)}
      checked={isRowChecked(params.row.id)}
    />
  );

  const headerCheckboxDisabled = () => included ? (selectedIncludes.length === 0) : (selectedItems.length === 0);
  const renderHeaderCheckbox = () => (
    <Checkbox
      color='primary'
      checkedIcon={<IndeterminateCheckBoxIcon />}
      disabled={headerCheckboxDisabled()}
      checked={!headerCheckboxDisabled()}
      onChange={() => included ? (setSelectedIncludes([])) : (setSelectedItems([]))}
    />
  )

  // insert included indicator at index 2
  const allItemsCols: GridColDef[] = JSON.parse(JSON.stringify([includedIndicatorCol].concat(cols)));
  allItemsCols.forEach(item => {
    if (item.width && !['checkbox', 'included'].includes(item.field)) {
      const w = item.width - (indicatorColWidth / allItemsCols.length);
      item.width = w;
      item.renderCell = (params) => renderCell(params, w);
    }
  });

  cols.unshift({
    field: 'checkbox',
    width: 65,
    sortable: false,
    renderHeader: renderHeaderCheckbox,
    renderCell: renderCheckbox
  });
  allItemsCols.unshift({
    field: 'checkbox',
    width: 65,
    sortable: false,
    renderHeader: renderHeaderCheckbox,
    renderCell: renderCheckbox
  });

  // items
  const [includedItems, setIncludedItems] = useState<any[]>([]);
  const [allItems, setAllItems] = useState<any[]>([]);
  //
  const allItemsIncludeStatusUpdated = useRef(false);
  // selection state
  const [selectedIncludes, setSelectedIncludes] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  // whether we are showing the included or all table
  const [included, setIncluded] = useState(true);
  const [triggerIncludedCheck, setTriggerIncludedCheck] = useState(false);

  // State for paginated data fetching
  const [orderByVal, setOrderByVal] = useState(orderBy ? orderBy : 'id');
  const [ascending, setAscending] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  // the search value shown in the search bar
  const [shownSearch, setShownSearch] = useState('');
  // paginated list state
  // need both offsets to compare new one with old to figure out pagination
  // see fetchData()
  const [includedOffset, setIncludedOffset] = useState(0); // included items offset updated by the pagination component
  const [allOffset, setAllOffset] = useState(0); // all items offset updated by the pagination component
  const includedCurrOffset = useRef(0); // included items offset currently shown in the list
  const allCurrOffset = useRef(0); // all items offset currently shown in the list
  const [includedPage, setIncludedPage] = useState(0);
  const [allPage, setAllPage] = useState(0);
  const [totalItems, setTotalItems] = useState(-1);
  const [totalIncludedItems, setTotalIncludedItems] = useState(-1);
  const firstDoc = useRef<any>();
  const lastDoc = useRef<any>();
  const loading = useRef(false);
  const unsubscribes = useRef<Unsubscribe[]>([]);
  const pageSize = 10; // datagrid pagesize

  // FETCH HOOKS
  // fetch trackers asynchronously on load and when order or search values change
  useEffect(() => {
    if (typeof trackerId === 'string' && trackerId.length > 0) {
      if (!included) allItemsIncludeStatusUpdated.current = false;
      paginatedFetchData({
        collectionName: included ? includeColPath : itemType,
        countDoc: included ? `Tracker/${trackerId}/Items/summary` : `Aggregate/${itemType.toLowerCase()}`,
        parseCount: included ? (d) => d.get(`counts.${itemType.toLowerCase()}`) ?? 0 : undefined,
        setItems: included ? setIncludedItems : setAllItems,
        loading: loading,
        unsubscribes: unsubscribes,
        pageSize: pageSize,
        parseItem: parseItemData,
        hasNextPageMode: searchVal.length > 0 ? true : false,
        offset: included ? includedOffset : allOffset,
        currOffset: included ? includedCurrOffset : allCurrOffset,
        orderByVal: orderByVal,
        ascending: ascending,
        searchVal: searchVal,
        totalItems: included ? totalIncludedItems : totalItems,
        setTotalItems: included ? setTotalIncludedItems : setTotalItems,
        firstDoc: firstDoc,
        lastDoc: lastDoc,
      });
    }
    // unsubscribe listener on cleanup
    return function cleanUp() {
      unsubscribes.current.forEach(u => u());
      unsubscribes.current = [];
    }
  }, [ascending, includedOffset, allOffset]);
  // hook for when orderBy, exclude change (reset page / offset when changed)
  useEffect(() => {
    if (typeof trackerId === 'string' && trackerId.length > 0) {
      if (!included) allItemsIncludeStatusUpdated.current = false;
      // reset offset to zero
      included ? setIncludedOffset(0) : setAllOffset(0);
      included ? setIncludedPage(0) : setAllPage(0);
      paginatedFetchData({
        collectionName: included ? includeColPath : itemType,
        countDoc: included ? `Tracker/${trackerId}/Items/summary` : `Aggregate/${itemType.toLowerCase()}`,
        parseCount: included ? (d) => d.get(`counts.${itemType.toLowerCase()}`) ?? 0 : undefined,
        setItems: included ? setIncludedItems : setAllItems,
        loading: loading,
        unsubscribes: unsubscribes,
        pageSize: pageSize,
        parseItem: parseItemData,
        hasNextPageMode: searchVal.length > 0 ? true : false,
        offset: included ? includedOffset : allOffset,
        currOffset: included ? includedCurrOffset : allCurrOffset,
        orderByVal: orderByVal,
        ascending: ascending,
        searchVal: searchVal,
        totalItems: included ? totalIncludedItems : totalItems,
        setTotalItems: included ? setTotalIncludedItems : setTotalItems,
        firstDoc: firstDoc,
        lastDoc: lastDoc,
      });
    }
    // unsubscribe listener on cleanup
    return function cleanUp() {
      unsubscribes.current.forEach(u => u());
      unsubscribes.current = [];
    }
  }, [orderByVal, included, searchVal]);

  // after a page of 'all' items is loaded:
  //   - check if any are included by this tracker
  //   - check if the goal is at or below the org level of the fsm
  useEffect(() => {
    if (allItemsIncludeStatusUpdated.current && !triggerIncludedCheck) return void 0;
    if (!allItemsIncludeStatusUpdated.current) allItemsIncludeStatusUpdated.current = true;
    if (triggerIncludedCheck) setTriggerIncludedCheck(false);
    (async () => {
      let atLeastOneUpdate = false;
      const updatedItems = await Promise.all(allItems.map(async (item) => {
        // check if included
        let included = false;
        const itemDoc = await getDoc(doc(db, `Tracker/${trackerId}/Items/summary/${itemType}Include/${item.id}`));
        if (itemDoc.exists()) {
          if (!atLeastOneUpdate) atLeastOneUpdate = true;
          included = true;
        }

        // check if valid org for user
        let grayRow = false;
        if (
          itemType === 'Goal'
          && typeof item.org === 'string'
          && typeof userOrg === 'string'
          && userOrg !== ''
        ) {
          grayRow = true; // if item type is goal, guilty until proven innocent
          const orgDoc = await getDoc(doc(db, 'Org/' + item.org));
          for (const lvl of ['level1', 'level2', 'level3', 'level4', 'level5']) {
            const orgLvlId = orgDoc.get(lvl);
            if (typeof orgLvlId === 'string' && orgLvlId === userOrg) {
              if (!atLeastOneUpdate) atLeastOneUpdate = true;
              grayRow = false;
              break;
            }
          }
        }

        // update the item
        return ({ ...item, included: included, grayRow: grayRow });
      }));


      // only update if there was an include that changed an item
      if (atLeastOneUpdate) setAllItems(updatedItems);
    })();
  }, [allItems, triggerIncludedCheck]);


  // called when a user has selected some items from the 'included' data grid and
  //   then clicks 'exclude selected' button.
  // two cases need to be handled when excluding an item:
  //   1) the item is in the included list because of the filters; add this item to the 
  //     explicit excludes, and remove it from the Include list.
  //   2) the item is in the included list because it was previously explicitly included;
  //     remove it from the explicit include list and from the Include list.
  // Decrement the count as well.
  const excludeSelectedIncludesHandler = async () => {
    if (trackerId == null || (typeof trackerId === 'string' && trackerId.length === 0)) {
      console.error('cannot set includes and excludes on a Tracker without an ID!');
      return;
    }
    // check if each item is an explicit include
    for (const item of selectedIncludes) {
      if (item.id) {
        const itemDoc = await getDoc(doc(db, `Tracker/${trackerId}/Items/summary/${itemType}IncludeExplicit/${item.id}`));
        if (itemDoc.exists()) {
          // remove from explicit include list
          deleteDoc(itemDoc.ref);
        } else {
          // add to explicitly excluded list
          const ref = doc(db, `Tracker/${trackerId}/Items/summary/${itemType}ExcludeExplicit/${item.id}`);
          setDoc(ref, {id: item.id});
          // remove from include list
        }
        // remove from include list in both cases
        const incRef = doc(db, `Tracker/${trackerId}/Items/summary/${itemType}Include/${item.id}`);
        deleteDoc(incRef);
        // decrement the count
        const countSnap = await getDoc(doc(db, `Tracker/${trackerId}/Items/summary`));
        if (!countSnap.exists) return;
        let counts = countSnap.get('counts');
        if (typeof counts === 'object') {
          await updateDoc(countSnap.ref, `counts.${itemType.toLowerCase()}`, increment(-1));
        }
      }
    }
    // update the main tracker doc (triggers data table builds)
    if (trackerId.length > 0) {
      const tSnap = await getDoc(doc(db, `Tracker/${trackerId}`));
      const info = tSnap.get('info');
      if (info) {
        await updateDoc(tSnap.ref, 'info', { 
          ...info,
          updatedAt: new Date(),
          updatedBy: auth.currentUser?.email ?? 'unknown',
        });
      }
    }
  }

  // called when a user has selected some items from the 'all' data grid and
  //   then clicks 'include selected' button.
  // two cases need to be handled when including an item:
  //   1) the item is in the all list because wasn't included by filters; add this
  //     item to the explicit includes, and to the Include list.
  //   2) the item is in the all list because it was previously explicitly excluded;
  //     remove it from the explicit exclude list, and add it back to Include list.
  //   3*) handled: the item is in the all list but is already included. users won't
  //     be able to select these rows, so don't need to handle here.
  // Increment the count as well.
  const includeSelectedItemsHandler = async () => {
    if (trackerId == null || (typeof trackerId === 'string' && trackerId.length === 0)) {
      console.error('cannot set includes and excludes on a Tracker without an ID!');
      return;
    }
    // check if each item is an explicit exclude
    for (const i of selectedItems) {
      const item = JSON.parse(JSON.stringify(i)); // copy so we don't change state
      if (item.id) {
        // format item to firestore format
        item.goalPeriod = {};
        if (item['goalPeriod.startDate']) {
          item.goalPeriod.startDate = item['goalPeriod.startDate'];
          delete item['goalPeriod.startDate'];
        }
        if (item['goalPeriod.endDate']) {
          item.goalPeriod.endDate = item['goalPeriod.endDate'];
          delete item['goalPeriod.endDate'];
        }

        const incRef = doc(db, `Tracker/${trackerId}/Items/summary/${itemType}Include/${item.id}`);
        const itemDoc = await getDoc(doc(db, `Tracker/${trackerId}/Items/summary/${itemType}ExcludeExplicit/${item.id}`));
        if (itemDoc.exists()) {
          // remove from explicitly excluded list
          await deleteDoc(itemDoc.ref);
          // add to include list
          await setDoc(incRef, item);
        } else {
          // add to explicitly included list
          const ref = doc(db, `Tracker/${trackerId}/Items/summary/${itemType}IncludeExplicit/${item.id}`);
          await setDoc(ref, {id: item.id});
          // add to include list
          await setDoc(incRef, {...item, isExplicit: true});
        }
        // increment the count
        const countSnap = await getDoc(doc(db, `Tracker/${trackerId}/Items/summary`));
        if (!countSnap.exists) return;
        let counts = countSnap.get('counts');
        if (typeof counts === 'object') {
          await updateDoc(countSnap.ref, `counts.${itemType.toLowerCase()}`, increment(1));
        } else {
          const counts: any = {};
          counts[itemType.toLowerCase()] = 1;
          await updateDoc(countSnap.ref, 'counts', counts);
        }
      }
    }
    setTriggerIncludedCheck(true); // re calculate checked items
    // update the main tracker doc (triggers data table builds)
    if (trackerId.length > 0) {
      const tSnap = await getDoc(doc(db, `Tracker/${trackerId}`));
      const info = tSnap.get('info');
      if (info) {
        await updateDoc(tSnap.ref, 'info', { 
          ...info,
          updatedAt: new Date(),
          updatedBy: auth.currentUser?.email ?? 'unknown',
        });
      }
    }
  }

  const incExcButton = (
    <Button
      className={clsx(style.incExcButton, style.marginVert)}
      variant='contained'
      color='primary'
      disabled={included ? (selectedIncludes.length === 0) : (selectedItems.length === 0)}
      onClick={() => {
        setIsStateDirty(true);
        if (included) {
          excludeSelectedIncludesHandler();
          setSelectedIncludes([]);
        } else {
          includeSelectedItemsHandler();
          setSelectedItems([]);
        }

      }}
    >
      {`${included ? 'Exclude' : 'Include'} Selected`}
    </Button>
  );

  // debounced func only fires after specified ms
  const setSearchValDebounced = debounce(setSearchVal, 300);

  let includedRowCount = 0;
  if (typeof totalIncludedItems === 'number' && totalIncludedItems > 0) {
    includedRowCount = totalIncludedItems;
  }
  let allRowCount = 0;
  if (typeof totalItems === 'number' && totalItems > 0) allRowCount = totalItems;

  function CustomPagination() {
    return (
      <div className={style.customPagination}>
        {/* only show the total if we know it. if there is a search, we don't know
        the total because firestore doesn't provide query aggregate info... */}
        {searchVal.length === 0 &&
          <div className={style.customPaginationCount}>
            {`${included ? (includedPage*pageSize) : (allPage*pageSize)} to ${included ? ((includedPage*pageSize) + includedItems.length) : ((allPage*pageSize) + allItems.length)}  of  ${included ? (includedItems.length > 0 ? includedRowCount : 0) : (allItems.length > 0 ? allRowCount : allItems.length)}`}
          </div>
        }
        <Button
          className={style.customPaginationArrows}
          disableRipple
          disabled={
            included ?
            (includedPage === 0)
            :
            (allPage === 0)
          }
          onClick={() => {
            if (included) {
              const page = includedPage - 1;
              setIncludedPage(page);
              setIncludedOffset(page * pageSize);
            } else {
              const page = allPage - 1;
              setAllPage(page);
              setAllOffset(page * pageSize);
            }
          }}
        >
          <ArrowBackIos fontSize='small' />
        </Button>
        <Button
          className={style.customPaginationArrows}
          disableRipple
          disabled={(searchVal.length === 0) ?
            (included ? ((includedPage*pageSize) + includedItems.length) : ((allPage*pageSize) + allItems.length)) === (included ? totalIncludedItems : totalItems)
            :
            // when there is a search term, the first item of the next page is fetched as well.
            //   if the number of fetched items is less than or equal to the pageSize,
            //   there is no next page.
            (included ? includedItems.length : allItems.length) <= pageSize
          }
          onClick={() => {
            if (included) {
              const page = includedPage + 1;
              setIncludedPage(page);
              setIncludedOffset(page * pageSize);
            } else {
              const page = allPage + 1;
              setAllPage(page);
              setAllOffset(page * pageSize);
            }
          }}
        >
          <ArrowForwardIos fontSize='small' />
        </Button>
      </div>
    )
  }

  return (
    <div className={style.root}>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="secondary">{itemType + ' Selections'}</Typography>
        </AccordionSummary>
        <AccordionDetails className={style.centeredContainer}>
          <div className={style.gridTop}>
            {staleDataMessage && isStateDirty && 
              <Typography color='secondary' variant='body2' className={style.saveMsg}>
                {staleDataMessage}
              </Typography>}
            <div className={style.gridTopRight}>
              <div className={style.search}>
                <div className={style.searchIcon}>
                  <Search />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: style.inputRoot,
                    input: style.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  value={shownSearch}
                  onChange={(e) => {
                    if (typeof e.target.value === 'string' && e.target.value.length <= 3) {
                      setShownSearch(e.target.value);
                      setSearchValDebounced(e.target.value);
                    }
                  }}
                />
              </div>
              <ButtonGroup className={style.marginVert} disableElevation size='medium'>
                <Button 
                  variant={included ? 'contained' : 'outlined'} 
                  color='primary'
                  onClick={() => {
                    if (!included) setIncluded(true);
                  }}
                >
                  Included
                </Button>
                <Button 
                  variant={included ? 'outlined' : 'contained'} 
                  color='primary'
                  onClick={() => {
                    if (included) setIncluded(false);
                  }}
                >
                  All
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <DataGrid
            className={style.dataGrid}
            rows={included ? includedItems : allItems}
            getRowClassName={(params) => {
              if (
                !included
                && ((typeof params.row.included === 'boolean' && params.row.included)
                || (typeof params.row.grayRow === 'boolean' && params.row.grayRow))
              ) {
                return style.grayedRow
              } else {
                return style.regRow;
              }
            }}
            rowHeight={50}
            autoHeight
            rowCount={searchVal.length > 0 ?
              undefined
              :
              (included ? (includedItems.length > 0 ? includedRowCount : 0) : (allItems.length > 0 ? allRowCount : allItems.length))
            }
            components={{
              Pagination: CustomPagination
            }}
            columns={included ? cols : allItemsCols}
            pageSize={pageSize}
            disableSelectionOnClick
            loading={loading.current}
            disableColumnMenu
            
            sortingOrder={['asc', 'desc']}
            // sorting
            sortingMode='server'
            //sortModel={sortModel}
            onSortModelChange={(model: GridSortModel) => {
              // datagrid returns empty array when header is clicked a third time which
              //   makes the grid go back to initial sort state. only update our
              //   orderBy and ascending for a valid sort.
              if (model.length > 0) {
                setOrderByVal(model[0].field);
                setAscending(model[0].sort === 'asc' ? true : false);
              }
            }}
          />
          {incExcButton}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}


const styles = makeStyles((theme: any) => ({
  root: {
    width: '95%',
  },
  fonts: {
    color: "#455A64",
  },
  dataGrid: {
    height: '100%',
    width: '100%',
    color: '#455A64'
  },
  regRow: {},
  grayedRow: {
    color: '#cfcfcf'
  },
  centeredContainer: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  incExcButton: {
    width: 230,
    alignSelf: 'flex-end'
  },
  marginVert: {
    marginTop: 5,
    marginBottom: 5
  },
  gridTop: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  gridTopRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexGrow: 1
  },
  saveMsg: {
    fontStyle: 'italic'
  },
  // search bar
  search: {
    display: 'flex',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#e3e3e3',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    transition: 'background-color 0.2s',
    marginTop: theme.spacing(1),
    marginBottom: 5,
    marginRight: 20,
    width: '40%',
    height: 37,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    color: '#455A64',
    paddingLeft: theme.spacing(1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    color: '#455A64',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  customPagination: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'end',
    paddingRight: '10px'
  },
  customPaginationCount: {
    marginRight: '20px',
  },
  customPaginationArrows: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));